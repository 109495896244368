.tidy-big-icon{
  width: 88px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #333;
  font-family: var(--title-booking);
  .tidy-item-icon{
    color: white;
    background: #000;
    border-radius: 30px;
    font-size: 25px;
    display: inline-block;
    width: 62px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ion-icon{
    margin-right: 0;
  }
  .tidy-item-text{
    text-align: center;
    margin-top: 10px;
  }
}
