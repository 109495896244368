tidy-card {
  background: white;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.24), 0px 0px 3px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  display: block;
  padding: 20px;
  --ion-font-family: var(--title-booking);
  font-family: var(--title-booking);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #000;
  margin-bottom: 20px;

  ion-icon {
    color:rgba(0, 0, 0, 0.5);
    font-size: 20px;
    width: 1em;
    height: 1em;
  }

  card-header{
    margin-bottom: 10px;
  }

  .tidy-container {
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
  }

  .tidy-item {
    align-self: flex-start;
    margin: 0;
  }


  tidy-card-title  {
    span {
      font-family: Georgia;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 16px;
      b{
        text-transform: capitalize;
      }
    }
  }

  .regular-text, .regular-text *, ion-albel.regular-text{
    font-weight: 300;
    font-size: 16px;
    color: #000;
  }

  tidy-card-item  {
    .tidy-container {
      justify-content: start;
      border-bottom: none;
      margin: 10px 0px;
    }
    ion-icon{
      margin-right: 5px;
    }
    .tidy-item {
      align-self: flex-start;
      p{
        margin: 0;
        font-size: 14px;
      }
    }

  }

  .tidy-radio {
    background: none;
    padding-left: 0;
    --pading-start: 0;
    ion-radio {
      margin-left: 0 !important;
      margin-right: 11px !important;
    }
  }

  .item-radio-checked.item-md ion-label, .item-radio.item-md ion-label, .item-radio.item-ios ion-label {
    font-family: 'Inter';
    font-weight: 300;
    font-size: 15px;
    color: #000;
  }

  .item-md .radio-md .radio-inner{
    background-color: var(--ion-color-tidy_green);
    height: 10px;
    width: 10px;
  }

  .item-md .radio-md .radio-checked {
    border-color: var(--ion-color-tidy_green);
  }

  .item-md .radio-md .radio-icon{
    height: 22px;
    width: 22px;
  }



  .card-title{
    margin-bottom: 20px;
    display: block;
  }
  .grey-line-full{
    border-bottom: 1px solid #dedede;
    margin: 10px 0px;
  }

  .grey-line{
    border-bottom: 1px solid #dedede;
    margin: 15px 0px 0px 10%;
  }

  tidy-card-list {
    .tidy-container {
      border-bottom: none;
      margin: 10px 0px;
    }
    .exclude-icon {
      font-size: 20px;
      margin-top: 5px;
      font-weight: bold;
      color: #333333;
    }
    .tidy-list-item {
      align-self: flex-start;
      margin: 0;
      flex: 1;
    }
    .tidy-list-item-middle{
      flex: 9;
    }
    tidy-link{
      font-size: 16px;
    }
    .tidy-list-border{
      width: 100%;
    }
    tidy-card-date{
      font-size: 12px;
    }
  }


  .no-wrap {
    min-width: 100%;
  }
}

ion-label {
  margin: 0;
  padding: 0;
}

tidy-checkbox{
  ion-checkbox{
    --border-color: rgb(190, 191, 192);
    top: 5px;
    position: absolute;
  }
  ion-item{
    --inner-border-width: 0;
    margin: 10px 0;
    --min-height: auto;
  }
  ion-label{
    margin: 0 0 0 25px !important;
  }
  .checkbox-description{
    font-size: 12px;
    color: #333;
  }
  .checkbox-checked {
    --background-checked: #41CAB7;
    --border-color-checked: #41CAB7;
    --border-color: #41CAB7;
  }
  .checkbox-icon{
    width: 18px !important;
    height: 18px !important;
  }
  .checkbox-inner{
    width: 8px !important;
    height: 12px !important;
    top: 0px  !important;
    left: 3px !important;
  }
  ion-item.item-checkbox{
    min-height: auto;
    margin: 20px 0;
    padding: 0;
    background: none;
    align-items: baseline;
    ion-label{
      margin: 0;
    }
    .checkbox{
      margin: 0 10px 0 0 !important;
    }
    .item-inner{
      padding: 0;
    }
  }
}
