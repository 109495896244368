photo-note, cleaning-history {
  .swiper-container-horizontal > .swiper-pagination {
    color: white;
    top: auto;
    bottom: 0;

    > .swiper-pagination-bullet {
      border: 1px solid var(--ion-color-caption_dark);
      width: 9px;
      height: 9px;
  
      &.swiper-pagination-bullet-active {
        background: var(--ion-color-caption_dark);
      }
    }
  }

  .slide-index {
    text-align: center;
    position: absolute;
    background: #000;
    color: #fff;
    border-radius: 10px 10px 10px 10px;
    width: 35px;
    height: 20px;
    left: 13px;
    top: 13px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    z-index: 10;
    display: block;
  }
}
