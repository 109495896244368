cleaning-history .tidy-square .icon-todo, page-todos .tidy-square .icon-todo{
  padding-left: 55px;
  background-repeat: no-repeat;
  background-size: 39px;
  background-position: left top;
  padding-bottom: 15px;

  &.icon-todo-main_workspace{
    background-image: url("/assets/img/icons/office.svg");
    background-size: 33px;
    background-position: 0 0;
  }
  &.icon-todo-reception_area{
    background-image: url("/assets/img/icons/house-icon.svg");
    background-size: 33px;
    background-position: 0 0;
  }
  &.icon-todo-kitchen{
    background-image: url("/assets/img/icons/kitchen.svg");
    background-size: 37px;
  }
  &.icon-todo-bedroom{
    background-image: url("/assets/img/icons/bedroom.svg");
    background-size: 33px;
    background-position: 0 3px;
  }
  &.icon-todo-bathroom{
    background-image: url("/assets/img/icons/bathroom.svg");
    background-size: 33px;
    background-position: 0 0;
  }

  &.icon-todo-dining_room{
    background-image: url("/assets/img/icons/dining_room.svg");
  }
  &.icon-todo-hallway {
    background-image: url("/assets/img/icons/hallway.svg");
    background-size: 30px;
    background-position: left top;
  }
  &.icon-todo-laundry {
    background-image: url("/assets/img/icons/laundry.svg");
    background-size: 30px;
  }
  &.icon-todo-office {
    background-image: url("/assets/img/icons/office.svg");
  }
  &.icon-todo-patio {
    background-image: url("/assets/img/icons/patio.svg");
  }
  &.icon-todo-stairwell {
    background-image: url("/assets/img/icons/stairwell.svg");
    background-position: 0 2px;
  }
  &.icon-todo-front_yard {
    background-image: url("/assets/img/icons/back_yard.png");
    background-position: 0 2px;
  }
  &.icon-todo-back_yard {
    background-image: url("/assets/img/icons/front_yard.png");
    background-position: 0 2px;
  }
  &.icon-todo-living_room {
    background-image: url("/assets/img/icons/living_room.svg");
    background-position: 0 5px;
  }
  &.icon-todo-garage {
    background-image: url("/assets/img/icons/living_room.svg");
    background-position: 0 10px;
  }
  &.icon-todo-home {
    background-image: url("/assets/img/icons/lock-icon.svg");
    background-size: 25px;
    background-position: left top;
  }
  &.icon-todo-closing {
    background-image: url("/assets/img/icons/house-icon.svg");
    background-size: 25px;
    background-position: left top;
  }
}
