ion-icon {
  &.ion-ios-pin{
    text-align: center;
    opacity: 0.6;
    font-size: 22px;
  }
  &[class*='tidy'], &[name*='ios-'], &[class*='ios-'] {
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background: currentColor;
  }
  &.ios-time-outline {
    mask-image: url('/assets/ionic-icon-v3/ios-time-outline.svg');
  }
  &.ios-help-circle-outline {
    mask-image: url('/assets/ionic-icon-v3/ios-help-circle-outline.svg');
  }
  &.ios-time-outline {
    mask-image: url('/assets/ionic-icon-v3/ios-time-outline.svg');
  }
  &.ios-checkmark-circle-outline {
    mask-image: url('/assets/ionic-icon-v3/ios-checkmark-circle-outline.svg');
  }
  &.ios-alert-outline {
    mask-image: url('/assets/ionic-icon-v3/ios-alert-outline.svg');
  }
  &.ios-search-outline {
    mask-image: url('/assets/ionic-icon-v3/ios-search-outline.svg');
  }
  &[name='ios-alert-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-alert-outline.svg');
  }
  &[name*='ios-camera-outline.svg'] {
    mask-image: url('/assets/ionic-icon-v3/ios-camera-outline.svg');
  }
  &[class*='tidy-pencil'] {
    mask-image: url('/assets/img/icons/pencil-icon.svg');
  }
  &[class*='tidy-close'] {
    mask-image: url('/assets/img/icon-close.svg');
  }
  &[class*='tidy-concierge-person'] {
    mask-image: url('/assets/img/tidy-logo.png');
  }
  &[class*='tidy-bowtie'] {
    mask-image: url('/assets/img/tidy-bow-outline.svg');
  }
  &[class*='tidy-card'] {
    mask-image: url('/assets/img/icons/card-ico.svg');
  }
  &[class*='tidy-dolar'] {
    mask-image: url('/assets/img/icons/dolar-ico.svg');
  }
  &[class*='tidy-needs-work'] {
    width: 100%;
    height: 100%;
    background: url('/assets/img/icons/needs-to-work.png') center no-repeat;
  }
  &[class*='tidy-love-it'] {
    width: 100%;
    height: 100%;
    background: url('/assets/img/icons/love-it.png') center no-repeat;
  }
  &[name='ios-add-circle-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-add-circle-outline.svg');
  }

  &[name='ios-add-circle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-add-circle.svg');
  }

  &[name='ios-add'] {
    mask-image: url('/assets/ionic-icon-v3/ios-add.svg');
  }

  &[name='ios-alarm-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-alarm-outline.svg');
  }

  &[name='ios-alarm'] {
    mask-image: url('/assets/ionic-icon-v3/ios-alarm.svg');
  }

  &[name='ios-albums-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-albums-outline.svg');
  }

  &[name='ios-albums'] {
    mask-image: url('/assets/ionic-icon-v3/ios-albums.svg');
  }

  &[name='ios-alert'] {
    mask-image: url('/assets/ionic-icon-v3/ios-alert.svg');
  }

  &[name='ios-american-football-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-american-football-outline.svg');
  }

  &[name='ios-american-football'] {
    mask-image: url('/assets/ionic-icon-v3/ios-american-football.svg');
  }

  &[name='ios-analytics-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-analytics-outline.svg');
  }

  &[name='ios-analytics'] {
    mask-image: url('/assets/ionic-icon-v3/ios-analytics.svg');
  }

  &[name='ios-aperture-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-aperture-outline.svg');
  }

  &[name='ios-aperture'] {
    mask-image: url('/assets/ionic-icon-v3/ios-aperture.svg');
  }

  &[name='ios-apps-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-apps-outline.svg');
  }

  &[name='ios-apps'] {
    mask-image: url('/assets/ionic-icon-v3/ios-apps.svg');
  }

  &[name='ios-appstore-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-appstore-outline.svg');
  }

  &[name='ios-appstore'] {
    mask-image: url('/assets/ionic-icon-v3/ios-appstore.svg');
  }

  &[name='ios-archive-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-archive-outline.svg');
  }

  &[name='ios-archive'] {
    mask-image: url('/assets/ionic-icon-v3/ios-archive.svg');
  }

  &[name='ios-arrow-back'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-back.svg');
  }

  &[name='ios-arrow-down'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-down.svg');
  }

  &[name='ios-arrow-dropdown-circle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-dropdown-circle.svg');
  }

  &[name='ios-arrow-dropdown'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-dropdown.svg');
  }

  &[name='ios-arrow-dropleft-circle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-dropleft-circle.svg');
  }

  &[name='ios-arrow-dropleft'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-dropleft.svg');
  }

  &[name='ios-arrow-dropright-circle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-dropright-circle.svg');
  }

  &[name='ios-arrow-dropright'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-dropright.svg');
  }

  &[name='ios-arrow-dropup-circle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-dropup-circle.svg');
  }

  &[name='ios-arrow-dropup'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-dropup.svg');
  }

  &[name='ios-arrow-forward'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-forward.svg');
  }

  &[name='ios-arrow-round-back'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-round-back.svg');
  }

  &[name='ios-arrow-round-down'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-round-down.svg');
  }

  &[name='ios-arrow-round-forward'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-round-forward.svg');
  }

  &[name='ios-arrow-round-up'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-round-up.svg');
  }

  &[name='ios-arrow-up'] {
    mask-image: url('/assets/ionic-icon-v3/ios-arrow-up.svg');
  }

  &[name='ios-at-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-at-outline.svg');
  }

  &[name='ios-at'] {
    mask-image: url('/assets/ionic-icon-v3/ios-at.svg');
  }

  &[name='ios-attach'] {
    mask-image: url('/assets/ionic-icon-v3/ios-attach.svg');
  }

  &[name='ios-backspace-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-backspace-outline.svg');
  }

  &[name='ios-backspace'] {
    mask-image: url('/assets/ionic-icon-v3/ios-backspace.svg');
  }

  &[name='ios-barcode-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-barcode-outline.svg');
  }

  &[name='ios-barcode'] {
    mask-image: url('/assets/ionic-icon-v3/ios-barcode.svg');
  }

  &[name='ios-baseball-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-baseball-outline.svg');
  }

  &[name='ios-baseball'] {
    mask-image: url('/assets/ionic-icon-v3/ios-baseball.svg');
  }

  &[name='ios-basket-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-basket-outline.svg');
  }

  &[name='ios-basket'] {
    mask-image: url('/assets/ionic-icon-v3/ios-basket.svg');
  }

  &[name='ios-basketball-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-basketball-outline.svg');
  }

  &[name='ios-basketball'] {
    mask-image: url('/assets/ionic-icon-v3/ios-basketball.svg');
  }

  &[name='ios-battery-charging'] {
    mask-image: url('/assets/ionic-icon-v3/ios-battery-charging.svg');
  }

  &[name='ios-battery-dead'] {
    mask-image: url('/assets/ionic-icon-v3/ios-battery-dead.svg');
  }

  &[name='ios-battery-full'] {
    mask-image: url('/assets/ionic-icon-v3/ios-battery-full.svg');
  }

  &[name='ios-beaker-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-beaker-outline.svg');
  }

  &[name='ios-beaker'] {
    mask-image: url('/assets/ionic-icon-v3/ios-beaker.svg');
  }

  &[name='ios-beer-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-beer-outline.svg');
  }

  &[name='ios-beer'] {
    mask-image: url('/assets/ionic-icon-v3/ios-beer.svg');
  }

  &[name='ios-bicycle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bicycle.svg');
  }

  &[name='ios-bluetooth'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bluetooth.svg');
  }

  &[name='ios-boat-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-boat-outline.svg');
  }

  &[name='ios-boat'] {
    mask-image: url('/assets/ionic-icon-v3/ios-boat.svg');
  }

  &[name='ios-body-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-body-outline.svg');
  }

  &[name='ios-body'] {
    mask-image: url('/assets/ionic-icon-v3/ios-body.svg');
  }

  &[name='ios-bonfire-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bonfire-outline.svg');
  }

  &[name='ios-bonfire'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bonfire.svg');
  }

  &[name='ios-book-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-book-outline.svg');
  }

  &[name='ios-book'] {
    mask-image: url('/assets/ionic-icon-v3/ios-book.svg');
  }

  &[name='ios-bookmark-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bookmark-outline.svg');
  }

  &[name='ios-bookmark'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bookmark.svg');
  }

  &[name='ios-bookmarks-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bookmarks-outline.svg');
  }

  &[name='ios-bookmarks'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bookmarks.svg');
  }

  &[name='ios-bowtie-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bowtie-outline.svg');
  }

  &[name='ios-bowtie'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bowtie.svg');
  }

  &[name='ios-briefcase-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-briefcase-outline.svg');
  }

  &[name='ios-briefcase'] {
    mask-image: url('/assets/ionic-icon-v3/ios-briefcase.svg');
  }

  &[name='ios-browsers-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-browsers-outline.svg');
  }

  &[name='ios-browsers'] {
    mask-image: url('/assets/ionic-icon-v3/ios-browsers.svg');
  }

  &[name='ios-brush-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-brush-outline.svg');
  }

  &[name='ios-brush'] {
    mask-image: url('/assets/ionic-icon-v3/ios-brush.svg');
  }

  &[name='ios-bug-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bug-outline.svg');
  }

  &[name='ios-bug'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bug.svg');
  }

  &[name='ios-build-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-build-outline.svg');
  }

  &[name='ios-build'] {
    mask-image: url('/assets/ionic-icon-v3/ios-build.svg');
  }

  &[name='ios-bulb-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bulb-outline.svg');
  }

  &[name='ios-bulb'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bulb.svg');
  }

  &[name='ios-bus-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bus-outline.svg');
  }

  &[name='ios-bus'] {
    mask-image: url('/assets/ionic-icon-v3/ios-bus.svg');
  }

  &[name='ios-cafe-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cafe-outline.svg');
  }

  &[name='ios-cafe'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cafe.svg');
  }

  &[name='ios-calculator-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-calculator-outline.svg');
  }

  &[name='ios-calculator'] {
    mask-image: url('/assets/ionic-icon-v3/ios-calculator.svg');
  }

  &[name='ios-calendar-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-calendar-outline.svg');
  }

  &[name='ios-calendar'] {
    mask-image: url('/assets/ionic-icon-v3/ios-calendar.svg');
  }

  &[name='ios-call-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-call-outline.svg');
  }

  &[name='ios-call'] {
    mask-image: url('/assets/ionic-icon-v3/ios-call.svg');
  }

  &[name='ios-camera-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-camera-outline.svg');
  }

  &[name='ios-camera'] {
    mask-image: url('/assets/ionic-icon-v3/ios-camera.svg');
  }

  &[name='ios-car-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-car-outline.svg');
  }

  &[name='ios-car'] {
    mask-image: url('/assets/ionic-icon-v3/ios-car.svg');
  }

  &[name='ios-card-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-card-outline.svg');
  }

  &[name='ios-card-v3'] {
    mask-image: url('/assets/ionic-icon-v3/ios-card.svg');
  }

  &[name='ios-cart-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cart-outline.svg');
  }

  &[name='ios-cart'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cart.svg');
  }

  &[name='ios-cash-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cash-outline.svg');
  }

  &[name='ios-cash'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cash.svg');
  }

  &[name='ios-chatboxes-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-chatboxes-outline.svg');
  }

  &[name='ios-chatboxes'] {
    mask-image: url('/assets/ionic-icon-v3/ios-chatboxes.svg');
  }

  &[name='ios-chatbubbles-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-chatbubbles-outline.svg');
  }

  &[name='ios-chatbubbles'] {
    mask-image: url('/assets/ionic-icon-v3/ios-chatbubbles.svg');
  }

  &[name='ios-checkbox-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-checkbox-outline.svg');
  }

  &[name='ios-checkbox'] {
    mask-image: url('/assets/ionic-icon-v3/ios-checkbox.svg');
  }

  &[name='ios-checkmark-circle-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-checkmark-circle-outline.svg');
  }

  &[name='ios-checkmark-circle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-checkmark-circle.svg');
  }

  &[name='ios-checkmark'] {
    mask-image: url('/assets/ionic-icon-v3/ios-checkmark.svg');
  }

  &[name='ios-clipboard-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-clipboard-outline.svg');
  }

  &[name='ios-clipboard'] {
    mask-image: url('/assets/ionic-icon-v3/ios-clipboard.svg');
  }

  &[name='ios-clock-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-clock-outline.svg');
  }

  &[name='ios-clock'] {
    mask-image: url('/assets/ionic-icon-v3/ios-clock.svg');
  }

  &[name='ios-close-circle-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-close-circle-outline.svg');
  }

  &[name='ios-close-circle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-close-circle.svg');
  }

  &[name='ios-close'] {
    mask-image: url('/assets/ionic-icon-v3/ios-close.svg');
  }

  &[name='ios-closed-captioning-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-closed-captioning-outline.svg');
  }

  &[name='ios-closed-captioning'] {
    mask-image: url('/assets/ionic-icon-v3/ios-closed-captioning.svg');
  }

  &[name='ios-cloud-circle-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloud-circle-outline.svg');
  }

  &[name='ios-cloud-circle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloud-circle.svg');
  }

  &[name='ios-cloud-done-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloud-done-outline.svg');
  }

  &[name='ios-cloud-done'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloud-done.svg');
  }

  &[name='ios-cloud-download-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloud-download-outline.svg');
  }

  &[name='ios-cloud-download'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloud-download.svg');
  }

  &[name='ios-cloud-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloud-outline.svg');
  }

  &[name='ios-cloud-upload-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloud-upload-outline.svg');
  }

  &[name='ios-cloud-upload'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloud-upload.svg');
  }

  &[name='ios-cloud'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloud.svg');
  }

  &[name='ios-cloudy-night-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloudy-night-outline.svg');
  }

  &[name='ios-cloudy-night'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloudy-night.svg');
  }

  &[name='ios-cloudy-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloudy-outline.svg');
  }

  &[name='ios-cloudy'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cloudy.svg');
  }

  &[name='ios-code-download'] {
    mask-image: url('/assets/ionic-icon-v3/ios-code-download.svg');
  }

  &[name='ios-code-working'] {
    mask-image: url('/assets/ionic-icon-v3/ios-code-working.svg');
  }

  &[name='ios-code'] {
    mask-image: url('/assets/ionic-icon-v3/ios-code.svg');
  }

  &[name='ios-cog-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cog-outline.svg');
  }

  &[name='ios-cog'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cog.svg');
  }

  &[name='ios-color-fill-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-color-fill-outline.svg');
  }

  &[name='ios-color-fill'] {
    mask-image: url('/assets/ionic-icon-v3/ios-color-fill.svg');
  }

  &[name='ios-color-filter-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-color-filter-outline.svg');
  }

  &[name='ios-color-filter'] {
    mask-image: url('/assets/ionic-icon-v3/ios-color-filter.svg');
  }

  &[name='ios-color-palette-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-color-palette-outline.svg');
  }

  &[name='ios-color-palette'] {
    mask-image: url('/assets/ionic-icon-v3/ios-color-palette.svg');
  }

  &[name='ios-color-wand-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-color-wand-outline.svg');
  }

  &[name='ios-color-wand'] {
    mask-image: url('/assets/ionic-icon-v3/ios-color-wand.svg');
  }

  &[name='ios-compass-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-compass-outline.svg');
  }

  &[name='ios-compass'] {
    mask-image: url('/assets/ionic-icon-v3/ios-compass.svg');
  }

  &[name='ios-construct-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-construct-outline.svg');
  }

  &[name='ios-construct'] {
    mask-image: url('/assets/ionic-icon-v3/ios-construct.svg');
  }

  &[name='ios-contact-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-contact-outline.svg');
  }

  &[name='ios-contact'] {
    mask-image: url('/assets/ionic-icon-v3/ios-contact.svg');
  }

  &[name='ios-contacts-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-contacts-outline.svg');
  }

  &[name='ios-contacts'] {
    mask-image: url('/assets/ionic-icon-v3/ios-contacts.svg');
  }

  &[name='ios-contract'] {
    mask-image: url('/assets/ionic-icon-v3/ios-contract.svg');
  }

  &[name='ios-contrast'] {
    mask-image: url('/assets/ionic-icon-v3/ios-contrast.svg');
  }

  &[name='ios-copy-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-copy-outline.svg');
  }

  &[name='ios-copy'] {
    mask-image: url('/assets/ionic-icon-v3/ios-copy.svg');
  }

  &[name='ios-create-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-create-outline.svg');
  }

  &[name='ios-create'] {
    mask-image: url('/assets/ionic-icon-v3/ios-create.svg');
  }

  &[name='ios-crop-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-crop-outline.svg');
  }

  &[name='ios-crop'] {
    mask-image: url('/assets/ionic-icon-v3/ios-crop.svg');
  }

  &[name='ios-cube-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cube-outline.svg');
  }

  &[name='ios-cube'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cube.svg');
  }

  &[name='ios-cut-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cut-outline.svg');
  }

  &[name='ios-cut'] {
    mask-image: url('/assets/ionic-icon-v3/ios-cut.svg');
  }

  &[name='ios-desktop-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-desktop-outline.svg');
  }

  &[name='ios-desktop'] {
    mask-image: url('/assets/ionic-icon-v3/ios-desktop.svg');
  }

  &[name='ios-disc-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-disc-outline.svg');
  }

  &[name='ios-disc'] {
    mask-image: url('/assets/ionic-icon-v3/ios-disc.svg');
  }

  &[name='ios-document-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-document-outline.svg');
  }

  &[name='ios-document'] {
    mask-image: url('/assets/ionic-icon-v3/ios-document.svg');
  }

  &[name='ios-done-all'] {
    mask-image: url('/assets/ionic-icon-v3/ios-done-all.svg');
  }

  &[name='ios-download-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-download-outline.svg');
  }

  &[name='ios-download'] {
    mask-image: url('/assets/ionic-icon-v3/ios-download.svg');
  }

  &[name='ios-easel-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-easel-outline.svg');
  }

  &[name='ios-easel'] {
    mask-image: url('/assets/ionic-icon-v3/ios-easel.svg');
  }

  &[name='ios-egg-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-egg-outline.svg');
  }

  &[name='ios-egg'] {
    mask-image: url('/assets/ionic-icon-v3/ios-egg.svg');
  }

  &[name='ios-exit-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-exit-outline.svg');
  }

  &[name='ios-exit'] {
    mask-image: url('/assets/ionic-icon-v3/ios-exit.svg');
  }

  &[name='ios-expand'] {
    mask-image: url('/assets/ionic-icon-v3/ios-expand.svg');
  }

  &[name='ios-eye-off-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-eye-off-outline.svg');
  }

  &[name='ios-eye-off'] {
    mask-image: url('/assets/ionic-icon-v3/ios-eye-off.svg');
  }

  &[name='ios-eye-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-eye-outline.svg');
  }

  &[name='ios-eye'] {
    mask-image: url('/assets/ionic-icon-v3/ios-eye.svg');
  }

  &[name='ios-fastforward-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-fastforward-outline.svg');
  }

  &[name='ios-fastforward'] {
    mask-image: url('/assets/ionic-icon-v3/ios-fastforward.svg');
  }

  &[name='ios-female'] {
    mask-image: url('/assets/ionic-icon-v3/ios-female.svg');
  }

  &[name='ios-filing-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-filing-outline.svg');
  }

  &[name='ios-filing'] {
    mask-image: url('/assets/ionic-icon-v3/ios-filing.svg');
  }

  &[name='ios-film-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-film-outline.svg');
  }

  &[name='ios-film'] {
    mask-image: url('/assets/ionic-icon-v3/ios-film.svg');
  }

  &[name='ios-finger-print'] {
    mask-image: url('/assets/ionic-icon-v3/ios-finger-print.svg');
  }

  &[name='ios-flag-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-flag-outline.svg');
  }

  &[name='ios-flag'] {
    mask-image: url('/assets/ionic-icon-v3/ios-flag.svg');
  }

  &[name='ios-flame-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-flame-outline.svg');
  }

  &[name='ios-flame'] {
    mask-image: url('/assets/ionic-icon-v3/ios-flame.svg');
  }

  &[name='ios-flash-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-flash-outline.svg');
  }

  &[name='ios-flash'] {
    mask-image: url('/assets/ionic-icon-v3/ios-flash.svg');
  }

  &[name='ios-flask-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-flask-outline.svg');
  }

  &[name='ios-flask'] {
    mask-image: url('/assets/ionic-icon-v3/ios-flask.svg');
  }

  &[name='ios-flower-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-flower-outline.svg');
  }

  &[name='ios-flower'] {
    mask-image: url('/assets/ionic-icon-v3/ios-flower.svg');
  }

  &[name='ios-folder-open-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-folder-open-outline.svg');
  }

  &[name='ios-folder-open'] {
    mask-image: url('/assets/ionic-icon-v3/ios-folder-open.svg');
  }

  &[name='ios-folder-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-folder-outline.svg');
  }

  &[name='ios-folder'] {
    mask-image: url('/assets/ionic-icon-v3/ios-folder.svg');
  }

  &[name='ios-football-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-football-outline.svg');
  }

  &[name='ios-football'] {
    mask-image: url('/assets/ionic-icon-v3/ios-football.svg');
  }

  &[name='ios-funnel-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-funnel-outline.svg');
  }

  &[name='ios-funnel'] {
    mask-image: url('/assets/ionic-icon-v3/ios-funnel.svg');
  }

  &[name='ios-game-controller-a-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-game-controller-a-outline.svg');
  }

  &[name='ios-game-controller-a'] {
    mask-image: url('/assets/ionic-icon-v3/ios-game-controller-a.svg');
  }

  &[name='ios-game-controller-b-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-game-controller-b-outline.svg');
  }

  &[name='ios-game-controller-b'] {
    mask-image: url('/assets/ionic-icon-v3/ios-game-controller-b.svg');
  }

  &[name='ios-git-branch'] {
    mask-image: url('/assets/ionic-icon-v3/ios-git-branch.svg');
  }

  &[name='ios-git-commit'] {
    mask-image: url('/assets/ionic-icon-v3/ios-git-commit.svg');
  }

  &[name='ios-git-compare'] {
    mask-image: url('/assets/ionic-icon-v3/ios-git-compare.svg');
  }

  &[name='ios-git-merge'] {
    mask-image: url('/assets/ionic-icon-v3/ios-git-merge.svg');
  }

  &[name='ios-git-network'] {
    mask-image: url('/assets/ionic-icon-v3/ios-git-network.svg');
  }

  &[name='ios-git-pull-request'] {
    mask-image: url('/assets/ionic-icon-v3/ios-git-pull-request.svg');
  }

  &[name='ios-glasses-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-glasses-outline.svg');
  }

  &[name='ios-glasses'] {
    mask-image: url('/assets/ionic-icon-v3/ios-glasses.svg');
  }

  &[name='ios-globe-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-globe-outline.svg');
  }

  &[name='ios-globe'] {
    mask-image: url('/assets/ionic-icon-v3/ios-globe.svg');
  }

  &[name='ios-grid-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-grid-outline.svg');
  }

  &[name='ios-grid'] {
    mask-image: url('/assets/ionic-icon-v3/ios-grid.svg');
  }

  &[name='ios-hammer-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-hammer-outline.svg');
  }

  &[name='ios-hammer'] {
    mask-image: url('/assets/ionic-icon-v3/ios-hammer.svg');
  }

  &[name='ios-hand-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-hand-outline.svg');
  }

  &[name='ios-hand'] {
    mask-image: url('/assets/ionic-icon-v3/ios-hand.svg');
  }

  &[name='ios-happy-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-happy-outline.svg');
  }

  &[name='ios-happy'] {
    mask-image: url('/assets/ionic-icon-v3/ios-happy.svg');
  }

  &[name='ios-headset-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-headset-outline.svg');
  }

  &[name='ios-headset'] {
    mask-image: url('/assets/ionic-icon-v3/ios-headset.svg');
  }

  &[name='ios-heart-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-heart-outline.svg');
  }

  &[name='ios-heart'] {
    mask-image: url('/assets/ionic-icon-v3/ios-heart.svg');
  }

  &[name='ios-help-buoy-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-help-buoy-outline.svg');
  }

  &[name='ios-help-buoy'] {
    mask-image: url('/assets/ionic-icon-v3/ios-help-buoy.svg');
  }

  &[name='ios-help-circle-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-help-circle-outline.svg');
  }

  &[name='ios-help-circle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-help-circle.svg');
  }

  &[name='ios-help'] {
    mask-image: url('/assets/ionic-icon-v3/ios-help.svg');
  }

  &[name='ios-home-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-home-outline.svg');
  }

  &[name='ios-home'] {
    mask-image: url('/assets/ionic-icon-v3/ios-home.svg');
  }

  &[name='ios-ice-cream-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-ice-cream-outline.svg');
  }

  &[name='ios-ice-cream'] {
    mask-image: url('/assets/ionic-icon-v3/ios-ice-cream.svg');
  }

  &[name='ios-image-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-image-outline.svg');
  }

  &[name='ios-image'] {
    mask-image: url('/assets/ionic-icon-v3/ios-image.svg');
  }

  &[name='ios-images-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-images-outline.svg');
  }

  &[name='ios-images'] {
    mask-image: url('/assets/ionic-icon-v3/ios-images.svg');
  }

  &[name='ios-infinite-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-infinite-outline.svg');
  }

  &[name='ios-infinite'] {
    mask-image: url('/assets/ionic-icon-v3/ios-infinite.svg');
  }

  &[name='ios-information-circle-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-information-circle-outline.svg');
  }

  &[name='ios-information-circle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-information-circle.svg');
  }

  &[name='ios-information'] {
    mask-image: url('/assets/ionic-icon-v3/ios-information.svg');
  }

  &[name='ios-ionic-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-ionic-outline.svg');
  }

  &[name='ios-ionic'] {
    mask-image: url('/assets/ionic-icon-v3/ios-ionic.svg');
  }

  &[name='ios-ionitron-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-ionitron-outline.svg');
  }

  &[name='ios-ionitron'] {
    mask-image: url('/assets/ionic-icon-v3/ios-ionitron.svg');
  }

  &[name='ios-jet-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-jet-outline.svg');
  }

  &[name='ios-jet'] {
    mask-image: url('/assets/ionic-icon-v3/ios-jet.svg');
  }

  &[name='ios-key-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-key-outline.svg');
  }

  &[name='ios-key'] {
    mask-image: url('/assets/ionic-icon-v3/ios-key.svg');
  }

  &[name='ios-keypad-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-keypad-outline.svg');
  }

  &[name='ios-keypad'] {
    mask-image: url('/assets/ionic-icon-v3/ios-keypad.svg');
  }

  &[name='ios-laptop'] {
    mask-image: url('/assets/ionic-icon-v3/ios-laptop.svg');
  }

  &[name='ios-leaf-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-leaf-outline.svg');
  }

  &[name='ios-leaf'] {
    mask-image: url('/assets/ionic-icon-v3/ios-leaf.svg');
  }

  &[name='ios-link-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-link-outline.svg');
  }

  &[name='ios-link'] {
    mask-image: url('/assets/ionic-icon-v3/ios-link.svg');
  }

  &[name='ios-list-box-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-list-box-outline.svg');
  }

  &[name='ios-list-box'] {
    mask-image: url('/assets/ionic-icon-v3/ios-list-box.svg');
  }

  &[name='ios-list'] {
    mask-image: url('/assets/ionic-icon-v3/ios-list.svg');
  }

  &[name='ios-locate-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-locate-outline.svg');
  }

  &[name='ios-locate'] {
    mask-image: url('/assets/ionic-icon-v3/ios-locate.svg');
  }

  &[name='ios-lock-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-lock-outline.svg');
  }

  &[name='ios-lock'] {
    mask-image: url('/assets/ionic-icon-v3/ios-lock.svg');
  }

  &[name='ios-log-in'] {
    mask-image: url('/assets/ionic-icon-v3/ios-log-in.svg');
  }

  &[name='ios-log-out'] {
    mask-image: url('/assets/ionic-icon-v3/ios-log-out.svg');
  }

  &[name='ios-magnet-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-magnet-outline.svg');
  }

  &[name='ios-magnet'] {
    mask-image: url('/assets/ionic-icon-v3/ios-magnet.svg');
  }

  &[name='ios-mail-open-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-mail-open-outline.svg');
  }

  &[name='ios-mail-open'] {
    mask-image: url('/assets/ionic-icon-v3/ios-mail-open.svg');
  }

  &[name='ios-mail-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-mail-outline.svg');
  }

  &[name='ios-mail'] {
    mask-image: url('/assets/ionic-icon-v3/ios-mail.svg');
  }

  &[name='ios-male'] {
    mask-image: url('/assets/ionic-icon-v3/ios-male.svg');
  }

  &[name='ios-man-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-man-outline.svg');
  }

  &[name='ios-man'] {
    mask-image: url('/assets/ionic-icon-v3/ios-man.svg');
  }

  &[name='ios-map-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-map-outline.svg');
  }

  &[name='ios-map'] {
    mask-image: url('/assets/ionic-icon-v3/ios-map.svg');
  }

  &[name='ios-medal-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-medal-outline.svg');
  }

  &[name='ios-medal'] {
    mask-image: url('/assets/ionic-icon-v3/ios-medal.svg');
  }

  &[name='ios-medical-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-medical-outline.svg');
  }

  &[name='ios-medical'] {
    mask-image: url('/assets/ionic-icon-v3/ios-medical.svg');
  }

  &[name='ios-medkit-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-medkit-outline.svg');
  }

  &[name='ios-medkit'] {
    mask-image: url('/assets/ionic-icon-v3/ios-medkit.svg');
  }

  &[name='ios-megaphone-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-megaphone-outline.svg');
  }

  &[name='ios-megaphone'] {
    mask-image: url('/assets/ionic-icon-v3/ios-megaphone.svg');
  }

  &[name='ios-menu-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-menu-outline.svg');
  }

  &[name='ios-menu'] {
    mask-image: url('/assets/ionic-icon-v3/ios-menu.svg');
  }

  &[name='ios-mic-off-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-mic-off-outline.svg');
  }

  &[name='ios-mic-off'] {
    mask-image: url('/assets/ionic-icon-v3/ios-mic-off.svg');
  }

  &[name='ios-mic-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-mic-outline.svg');
  }

  &[name='ios-mic'] {
    mask-image: url('/assets/ionic-icon-v3/ios-mic.svg');
  }

  &[name='ios-microphone-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-microphone-outline.svg');
  }

  &[name='ios-microphone'] {
    mask-image: url('/assets/ionic-icon-v3/ios-microphone.svg');
  }

  &[name='ios-moon-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-moon-outline.svg');
  }

  &[name='ios-moon'] {
    mask-image: url('/assets/ionic-icon-v3/ios-moon.svg');
  }

  &[name='ios-more-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-more-outline.svg');
  }

  &[name='ios-more'] {
    mask-image: url('/assets/ionic-icon-v3/ios-more.svg');
  }

  &[name='ios-move'] {
    mask-image: url('/assets/ionic-icon-v3/ios-move.svg');
  }

  &[name='ios-musical-note-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-musical-note-outline.svg');
  }

  &[name='ios-musical-note'] {
    mask-image: url('/assets/ionic-icon-v3/ios-musical-note.svg');
  }

  &[name='ios-musical-notes-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-musical-notes-outline.svg');
  }

  &[name='ios-musical-notes'] {
    mask-image: url('/assets/ionic-icon-v3/ios-musical-notes.svg');
  }

  &[name='ios-navigate-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-navigate-outline.svg');
  }

  &[name='ios-navigate'] {
    mask-image: url('/assets/ionic-icon-v3/ios-navigate.svg');
  }

  &[name='ios-no-smoking-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-no-smoking-outline.svg');
  }

  &[name='ios-no-smoking'] {
    mask-image: url('/assets/ionic-icon-v3/ios-no-smoking.svg');
  }

  &[name='ios-notifications-off-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-notifications-off-outline.svg');
  }

  &[name='ios-notifications-off'] {
    mask-image: url('/assets/ionic-icon-v3/ios-notifications-off.svg');
  }

  &[name='ios-notifications-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-notifications-outline.svg');
  }

  &[name='ios-notifications'] {
    mask-image: url('/assets/ionic-icon-v3/ios-notifications.svg');
  }

  &[name='ios-nuclear-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-nuclear-outline.svg');
  }

  &[name='ios-nuclear'] {
    mask-image: url('/assets/ionic-icon-v3/ios-nuclear.svg');
  }

  &[name='ios-nutrition-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-nutrition-outline.svg');
  }

  &[name='ios-nutrition'] {
    mask-image: url('/assets/ionic-icon-v3/ios-nutrition.svg');
  }

  &[name='ios-open-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-open-outline.svg');
  }

  &[name='ios-open'] {
    mask-image: url('/assets/ionic-icon-v3/ios-open.svg');
  }

  &[name='ios-options-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-options-outline.svg');
  }

  &[name='ios-options'] {
    mask-image: url('/assets/ionic-icon-v3/ios-options.svg');
  }

  &[name='ios-outlet-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-outlet-outline.svg');
  }

  &[name='ios-outlet'] {
    mask-image: url('/assets/ionic-icon-v3/ios-outlet.svg');
  }

  &[name='ios-paper-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-paper-outline.svg');
  }

  &[name='ios-paper-plane-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-paper-plane-outline.svg');
  }

  &[name='ios-paper-plane'] {
    mask-image: url('/assets/ionic-icon-v3/ios-paper-plane.svg');
  }

  &[name='ios-paper'] {
    mask-image: url('/assets/ionic-icon-v3/ios-paper.svg');
  }

  &[name='ios-partly-sunny-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-partly-sunny-outline.svg');
  }

  &[name='ios-partly-sunny'] {
    mask-image: url('/assets/ionic-icon-v3/ios-partly-sunny.svg');
  }

  &[name='ios-pause-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pause-outline.svg');
  }

  &[name='ios-pause'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pause.svg');
  }

  &[name='ios-paw-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-paw-outline.svg');
  }

  &[name='ios-paw'] {
    mask-image: url('/assets/ionic-icon-v3/ios-paw.svg');
  }

  &[name='ios-people-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-people-outline.svg');
  }

  &[name='ios-people'] {
    mask-image: url('/assets/ionic-icon-v3/ios-people.svg');
  }

  &[name='ios-person-add-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-person-add-outline.svg');
  }

  &[name='ios-person-add'] {
    mask-image: url('/assets/ionic-icon-v3/ios-person-add.svg');
  }

  &[name='ios-person-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-person-outline.svg');
  }

  &[name='ios-person'] {
    mask-image: url('/assets/ionic-icon-v3/ios-person.svg');
  }

  &[name='ios-phone-landscape'] {
    mask-image: url('/assets/ionic-icon-v3/ios-phone-landscape.svg');
  }

  &[name='ios-phone-portrait'] {
    mask-image: url('/assets/ionic-icon-v3/ios-phone-portrait.svg');
  }

  &[name='ios-photos-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-photos-outline.svg');
  }

  &[name='ios-photos'] {
    mask-image: url('/assets/ionic-icon-v3/ios-photos.svg');
  }

  &[name='ios-pie-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pie-outline.svg');
  }

  &[name='ios-pie'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pie.svg');
  }

  &[name='ios-pin-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pin-outline.svg');
  }

  &[name='ios-pin'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pin.svg');
  }

  &[name='ios-pint-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pint-outline.svg');
  }

  &[name='ios-pint'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pint.svg');
  }

  &[name='ios-pizza-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pizza-outline.svg');
  }

  &[name='ios-pizza'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pizza.svg');
  }

  &[name='ios-plane-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-plane-outline.svg');
  }

  &[name='ios-plane'] {
    mask-image: url('/assets/ionic-icon-v3/ios-plane.svg');
  }

  &[name='ios-planet-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-planet-outline.svg');
  }

  &[name='ios-planet'] {
    mask-image: url('/assets/ionic-icon-v3/ios-planet.svg');
  }

  &[name='ios-play-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-play-outline.svg');
  }

  &[name='ios-play'] {
    mask-image: url('/assets/ionic-icon-v3/ios-play.svg');
  }

  &[name='ios-podium-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-podium-outline.svg');
  }

  &[name='ios-podium'] {
    mask-image: url('/assets/ionic-icon-v3/ios-podium.svg');
  }

  &[name='ios-power-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-power-outline.svg');
  }

  &[name='ios-power'] {
    mask-image: url('/assets/ionic-icon-v3/ios-power.svg');
  }

  &[name='ios-pricetag-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pricetag-outline.svg');
  }

  &[name='ios-pricetag'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pricetag.svg');
  }

  &[name='ios-pricetags-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pricetags-outline.svg');
  }

  &[name='ios-pricetags'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pricetags.svg');
  }

  &[name='ios-print-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-print-outline.svg');
  }

  &[name='ios-print'] {
    mask-image: url('/assets/ionic-icon-v3/ios-print.svg');
  }

  &[name='ios-pulse-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pulse-outline.svg');
  }

  &[name='ios-pulse'] {
    mask-image: url('/assets/ionic-icon-v3/ios-pulse.svg');
  }

  &[name='ios-qr-scanner'] {
    mask-image: url('/assets/ionic-icon-v3/ios-qr-scanner.svg');
  }

  &[name='ios-quote-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-quote-outline.svg');
  }

  &[name='ios-quote'] {
    mask-image: url('/assets/ionic-icon-v3/ios-quote.svg');
  }

  &[name='ios-radio-button-off'] {
    mask-image: url('/assets/ionic-icon-v3/ios-radio-button-off.svg');
  }

  &[name='ios-radio-button-on'] {
    mask-image: url('/assets/ionic-icon-v3/ios-radio-button-on.svg');
  }

  &[name='ios-radio-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-radio-outline.svg');
  }

  &[name='ios-radio'] {
    mask-image: url('/assets/ionic-icon-v3/ios-radio.svg');
  }

  &[name='ios-rainy-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-rainy-outline.svg');
  }

  &[name='ios-rainy'] {
    mask-image: url('/assets/ionic-icon-v3/ios-rainy.svg');
  }

  &[name='ios-recording-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-recording-outline.svg');
  }

  &[name='ios-recording'] {
    mask-image: url('/assets/ionic-icon-v3/ios-recording.svg');
  }

  &[name='ios-redo-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-redo-outline.svg');
  }

  &[name='ios-redo'] {
    mask-image: url('/assets/ionic-icon-v3/ios-redo.svg');
  }

  &[name='ios-refresh-circle-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-refresh-circle-outline.svg');
  }

  &[name='ios-refresh-circle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-refresh-circle.svg');
  }

  &[name='ios-refresh'] {
    mask-image: url('/assets/ionic-icon-v3/ios-refresh.svg');
  }

  &[name='ios-remove-circle-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-remove-circle-outline.svg');
  }

  &[name='ios-remove-circle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-remove-circle.svg');
  }

  &[name='ios-remove'] {
    mask-image: url('/assets/ionic-icon-v3/ios-remove.svg');
  }

  &[name='ios-reorder'] {
    mask-image: url('/assets/ionic-icon-v3/ios-reorder.svg');
  }

  &[name='ios-repeat'] {
    mask-image: url('/assets/ionic-icon-v3/ios-repeat.svg');
  }

  &[name='ios-resize'] {
    mask-image: url('/assets/ionic-icon-v3/ios-resize.svg');
  }

  &[name='ios-restaurant-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-restaurant-outline.svg');
  }

  &[name='ios-restaurant'] {
    mask-image: url('/assets/ionic-icon-v3/ios-restaurant.svg');
  }

  &[name='ios-return-left'] {
    mask-image: url('/assets/ionic-icon-v3/ios-return-left.svg');
  }

  &[name='ios-return-right'] {
    mask-image: url('/assets/ionic-icon-v3/ios-return-right.svg');
  }

  &[name='ios-reverse-camera-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-reverse-camera-outline.svg');
  }

  &[name='ios-reverse-camera'] {
    mask-image: url('/assets/ionic-icon-v3/ios-reverse-camera.svg');
  }

  &[name='ios-rewind-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-rewind-outline.svg');
  }

  &[name='ios-rewind'] {
    mask-image: url('/assets/ionic-icon-v3/ios-rewind.svg');
  }

  &[name='ios-ribbon-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-ribbon-outline.svg');
  }

  &[name='ios-ribbon'] {
    mask-image: url('/assets/ionic-icon-v3/ios-ribbon.svg');
  }

  &[name='ios-rose-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-rose-outline.svg');
  }

  &[name='ios-rose'] {
    mask-image: url('/assets/ionic-icon-v3/ios-rose.svg');
  }

  &[name='ios-sad-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-sad-outline.svg');
  }

  &[name='ios-sad'] {
    mask-image: url('/assets/ionic-icon-v3/ios-sad.svg');
  }

  &[name='ios-school-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-school-outline.svg');
  }

  &[name='ios-school'] {
    mask-image: url('/assets/ionic-icon-v3/ios-school.svg');
  }

  &[name='ios-search-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-search-outline.svg');
  }

  &[name='ios-search'] {
    mask-image: url('/assets/ionic-icon-v3/ios-search.svg');
  }

  &[name='ios-send-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-send-outline.svg');
  }

  &[name='ios-send'] {
    mask-image: url('/assets/ionic-icon-v3/ios-send.svg');
  }

  &[name='ios-settings-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-settings-outline.svg');
  }

  &[name='ios-settings'] {
    mask-image: url('/assets/ionic-icon-v3/ios-settings.svg');
  }

  &[name='ios-share-alt-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-share-alt-outline.svg');
  }

  &[name='ios-share-alt'] {
    mask-image: url('/assets/ionic-icon-v3/ios-share-alt.svg');
  }

  &[name='ios-share-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-share-outline.svg');
  }

  &[name='ios-share'] {
    mask-image: url('/assets/ionic-icon-v3/ios-share.svg');
  }

  &[name='ios-shirt-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-shirt-outline.svg');
  }

  &[name='ios-shirt'] {
    mask-image: url('/assets/ionic-icon-v3/ios-shirt.svg');
  }

  &[name='ios-shuffle'] {
    mask-image: url('/assets/ionic-icon-v3/ios-shuffle.svg');
  }

  &[name='ios-skip-backward-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-skip-backward-outline.svg');
  }

  &[name='ios-skip-backward'] {
    mask-image: url('/assets/ionic-icon-v3/ios-skip-backward.svg');
  }

  &[name='ios-skip-forward-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-skip-forward-outline.svg');
  }

  &[name='ios-skip-forward'] {
    mask-image: url('/assets/ionic-icon-v3/ios-skip-forward.svg');
  }

  &[name='ios-snow-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-snow-outline.svg');
  }

  &[name='ios-snow'] {
    mask-image: url('/assets/ionic-icon-v3/ios-snow.svg');
  }

  &[name='ios-speedometer-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-speedometer-outline.svg');
  }

  &[name='ios-speedometer'] {
    mask-image: url('/assets/ionic-icon-v3/ios-speedometer.svg');
  }

  &[name='ios-square-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-square-outline.svg');
  }

  &[name='ios-square'] {
    mask-image: url('/assets/ionic-icon-v3/ios-square.svg');
  }

  &[name='ios-star-half'] {
    mask-image: url('/assets/ionic-icon-v3/ios-star-half.svg');
  }

  &[name='ios-star-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-star-outline.svg');
  }

  &[name='ios-star'] {
    mask-image: url('/assets/ionic-icon-v3/ios-star.svg');
  }

  &[name='ios-stats-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-stats-outline.svg');
  }

  &[name='ios-stats'] {
    mask-image: url('/assets/ionic-icon-v3/ios-stats.svg');
  }

  &[name='ios-stopwatch-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-stopwatch-outline.svg');
  }

  &[name='ios-stopwatch'] {
    mask-image: url('/assets/ionic-icon-v3/ios-stopwatch.svg');
  }

  &[name='ios-subway-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-subway-outline.svg');
  }

  &[name='ios-subway'] {
    mask-image: url('/assets/ionic-icon-v3/ios-subway.svg');
  }

  &[name='ios-sunny-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-sunny-outline.svg');
  }

  &[name='ios-sunny'] {
    mask-image: url('/assets/ionic-icon-v3/ios-sunny.svg');
  }

  &[name='ios-swap'] {
    mask-image: url('/assets/ionic-icon-v3/ios-swap.svg');
  }

  &[name='ios-switch-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-switch-outline.svg');
  }

  &[name='ios-switch'] {
    mask-image: url('/assets/ionic-icon-v3/ios-switch.svg');
  }

  &[name='ios-sync'] {
    mask-image: url('/assets/ionic-icon-v3/ios-sync.svg');
  }

  &[name='ios-tablet-landscape'] {
    mask-image: url('/assets/ionic-icon-v3/ios-tablet-landscape.svg');
  }

  &[name='ios-tablet-portrait'] {
    mask-image: url('/assets/ionic-icon-v3/ios-tablet-portrait.svg');
  }

  &[name='ios-tennisball-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-tennisball-outline.svg');
  }

  &[name='ios-tennisball'] {
    mask-image: url('/assets/ionic-icon-v3/ios-tennisball.svg');
  }

  &[name='ios-text-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-text-outline.svg');
  }

  &[name='ios-text'] {
    mask-image: url('/assets/ionic-icon-v3/ios-text.svg');
  }

  &[name='ios-thermometer-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-thermometer-outline.svg');
  }

  &[name='ios-thermometer'] {
    mask-image: url('/assets/ionic-icon-v3/ios-thermometer.svg');
  }

  &[name='ios-thumbs-down-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-thumbs-down-outline.svg');
  }

  &[name='ios-thumbs-down'] {
    mask-image: url('/assets/ionic-icon-v3/ios-thumbs-down.svg');
  }

  &[name='ios-thumbs-up-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-thumbs-up-outline.svg');
  }

  &[name='ios-thumbs-up'] {
    mask-image: url('/assets/ionic-icon-v3/ios-thumbs-up.svg');
  }

  &[name='ios-thunderstorm-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-thunderstorm-outline.svg');
  }

  &[name='ios-thunderstorm'] {
    mask-image: url('/assets/ionic-icon-v3/ios-thunderstorm.svg');
  }

  &[name='ios-time-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-time-outline.svg');
  }

  &[name='ios-time'] {
    mask-image: url('/assets/ionic-icon-v3/ios-time.svg');
  }

  &[name='ios-timer-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-timer-outline.svg');
  }

  &[name='ios-timer'] {
    mask-image: url('/assets/ionic-icon-v3/ios-timer.svg');
  }

  &[name='ios-train-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-train-outline.svg');
  }

  &[name='ios-train'] {
    mask-image: url('/assets/ionic-icon-v3/ios-train.svg');
  }

  &[name='ios-transgender'] {
    mask-image: url('/assets/ionic-icon-v3/ios-transgender.svg');
  }

  &[name='ios-trash-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-trash-outline.svg');
  }

  &[name='ios-trash'] {
    mask-image: url('/assets/ionic-icon-v3/ios-trash.svg');
  }

  &[name='ios-trending-down'] {
    mask-image: url('/assets/ionic-icon-v3/ios-trending-down.svg');
  }

  &[name='ios-trending-up'] {
    mask-image: url('/assets/ionic-icon-v3/ios-trending-up.svg');
  }

  &[name='ios-trophy-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-trophy-outline.svg');
  }

  &[name='ios-trophy'] {
    mask-image: url('/assets/ionic-icon-v3/ios-trophy.svg');
  }

  &[name='ios-umbrella-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-umbrella-outline.svg');
  }

  &[name='ios-umbrella'] {
    mask-image: url('/assets/ionic-icon-v3/ios-umbrella.svg');
  }

  &[name='ios-undo-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-undo-outline.svg');
  }

  &[name='ios-undo'] {
    mask-image: url('/assets/ionic-icon-v3/ios-undo.svg');
  }

  &[name='ios-unlock-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-unlock-outline.svg');
  }

  &[name='ios-unlock'] {
    mask-image: url('/assets/ionic-icon-v3/ios-unlock.svg');
  }

  &[name='ios-videocam-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-videocam-outline.svg');
  }

  &[name='ios-videocam'] {
    mask-image: url('/assets/ionic-icon-v3/ios-videocam.svg');
  }

  &[name='ios-volume-down'] {
    mask-image: url('/assets/ionic-icon-v3/ios-volume-down.svg');
  }

  &[name='ios-volume-mute'] {
    mask-image: url('/assets/ionic-icon-v3/ios-volume-mute.svg');
  }

  &[name='ios-volume-off'] {
    mask-image: url('/assets/ionic-icon-v3/ios-volume-off.svg');
  }

  &[name='ios-volume-up'] {
    mask-image: url('/assets/ionic-icon-v3/ios-volume-up.svg');
  }

  &[name='ios-walk'] {
    mask-image: url('/assets/ionic-icon-v3/ios-walk.svg');
  }

  &[name='ios-warning-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-warning-outline.svg');
  }

  &[name='ios-warning'] {
    mask-image: url('/assets/ionic-icon-v3/ios-warning.svg');
  }

  &[name='ios-watch'] {
    mask-image: url('/assets/ionic-icon-v3/ios-watch.svg');
  }

  &[name='ios-water-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-water-outline.svg');
  }

  &[name='ios-water'] {
    mask-image: url('/assets/ionic-icon-v3/ios-water.svg');
  }

  &[name='ios-wifi-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-wifi-outline.svg');
  }

  &[name='ios-wifi'] {
    mask-image: url('/assets/ionic-icon-v3/ios-wifi.svg');
  }

  &[name='ios-wine-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-wine-outline.svg');
  }

  &[name='ios-wine'] {
    mask-image: url('/assets/ionic-icon-v3/ios-wine.svg');
  }

  &[name='ios-woman-outline'] {
    mask-image: url('/assets/ionic-icon-v3/ios-woman-outline.svg');
  }

  &[name='ios-woman'] {
    mask-image: url('/assets/ionic-icon-v3/ios-woman.svg');
  }

  &[name='logo-android'] {
    mask-image: url('/assets/ionic-icon-v3/logo-android.svg');
  }

  &[name='logo-angular'] {
    mask-image: url('/assets/ionic-icon-v3/logo-angular.svg');
  }

  &[name='logo-apple'] {
    mask-image: url('/assets/ionic-icon-v3/logo-apple.svg');
  }

  &[name='logo-bitcoin'] {
    mask-image: url('/assets/ionic-icon-v3/logo-bitcoin.svg');
  }

  &[name='logo-buffer'] {
    mask-image: url('/assets/ionic-icon-v3/logo-buffer.svg');
  }

  &[name='logo-chrome'] {
    mask-image: url('/assets/ionic-icon-v3/logo-chrome.svg');
  }

  &[name='logo-codepen'] {
    mask-image: url('/assets/ionic-icon-v3/logo-codepen.svg');
  }

  &[name='logo-css3'] {
    mask-image: url('/assets/ionic-icon-v3/logo-css3.svg');
  }

  &[name='logo-designernews'] {
    mask-image: url('/assets/ionic-icon-v3/logo-designernews.svg');
  }

  &[name='logo-dribbble'] {
    mask-image: url('/assets/ionic-icon-v3/logo-dribbble.svg');
  }

  &[name='logo-dropbox'] {
    mask-image: url('/assets/ionic-icon-v3/logo-dropbox.svg');
  }

  &[name='logo-euro'] {
    mask-image: url('/assets/ionic-icon-v3/logo-euro.svg');
  }

  &[name='logo-facebook'] {
    mask-image: url('/assets/ionic-icon-v3/logo-facebook.svg');
  }

  &[name='logo-foursquare'] {
    mask-image: url('/assets/ionic-icon-v3/logo-foursquare.svg');
  }

  &[name='logo-freebsd-devil'] {
    mask-image: url('/assets/ionic-icon-v3/logo-freebsd-devil.svg');
  }

  &[name='logo-github'] {
    mask-image: url('/assets/ionic-icon-v3/logo-github.svg');
  }

  &[name='logo-google'] {
    mask-image: url('/assets/ionic-icon-v3/logo-google.svg');
  }

  &[name='logo-googleplus'] {
    mask-image: url('/assets/ionic-icon-v3/logo-googleplus.svg');
  }

  &[name='logo-hackernews'] {
    mask-image: url('/assets/ionic-icon-v3/logo-hackernews.svg');
  }

  &[name='logo-html5'] {
    mask-image: url('/assets/ionic-icon-v3/logo-html5.svg');
  }

  &[name='logo-instagram'] {
    mask-image: url('/assets/ionic-icon-v3/logo-instagram.svg');
  }

  &[name='logo-javascript'] {
    mask-image: url('/assets/ionic-icon-v3/logo-javascript.svg');
  }

  &[name='logo-linkedin'] {
    mask-image: url('/assets/ionic-icon-v3/logo-linkedin.svg');
  }

  &[name='logo-markdown'] {
    mask-image: url('/assets/ionic-icon-v3/logo-markdown.svg');
  }

  &[name='logo-nodejs'] {
    mask-image: url('/assets/ionic-icon-v3/logo-nodejs.svg');
  }

  &[name='logo-octocat'] {
    mask-image: url('/assets/ionic-icon-v3/logo-octocat.svg');
  }

  &[name='logo-pinterest'] {
    mask-image: url('/assets/ionic-icon-v3/logo-pinterest.svg');
  }

  &[name='logo-playstation'] {
    mask-image: url('/assets/ionic-icon-v3/logo-playstation.svg');
  }

  &[name='logo-python'] {
    mask-image: url('/assets/ionic-icon-v3/logo-python.svg');
  }

  &[name='logo-reddit'] {
    mask-image: url('/assets/ionic-icon-v3/logo-reddit.svg');
  }

  &[name='logo-rss'] {
    mask-image: url('/assets/ionic-icon-v3/logo-rss.svg');
  }

  &[name='logo-sass'] {
    mask-image: url('/assets/ionic-icon-v3/logo-sass.svg');
  }

  &[name='logo-skype'] {
    mask-image: url('/assets/ionic-icon-v3/logo-skype.svg');
  }

  &[name='logo-snapchat'] {
    mask-image: url('/assets/ionic-icon-v3/logo-snapchat.svg');
  }

  &[name='logo-steam'] {
    mask-image: url('/assets/ionic-icon-v3/logo-steam.svg');
  }

  &[name='logo-tumblr'] {
    mask-image: url('/assets/ionic-icon-v3/logo-tumblr.svg');
  }

  &[name='logo-tux'] {
    mask-image: url('/assets/ionic-icon-v3/logo-tux.svg');
  }

  &[name='logo-twitch'] {
    mask-image: url('/assets/ionic-icon-v3/logo-twitch.svg');
  }

  &[name='logo-twitter'] {
    mask-image: url('/assets/ionic-icon-v3/logo-twitter.svg');
  }

  &[name='logo-usd'] {
    mask-image: url('/assets/ionic-icon-v3/logo-usd.svg');
  }

  &[name='logo-vimeo'] {
    mask-image: url('/assets/ionic-icon-v3/logo-vimeo.svg');
  }

  &[name='logo-whatsapp'] {
    mask-image: url('/assets/ionic-icon-v3/logo-whatsapp.svg');
  }

  &[name='logo-windows'] {
    mask-image: url('/assets/ionic-icon-v3/logo-windows.svg');
  }

  &[name='logo-wordpress'] {
    mask-image: url('/assets/ionic-icon-v3/logo-wordpress.svg');
  }

  &[name='logo-xbox'] {
    mask-image: url('/assets/ionic-icon-v3/logo-xbox.svg');
  }

  &[name='logo-yahoo'] {
    mask-image: url('/assets/ionic-icon-v3/logo-yahoo.svg');
  }

  &[name='logo-yen'] {
    mask-image: url('/assets/ionic-icon-v3/logo-yen.svg');
  }

  &[name='logo-youtube'] {
    mask-image: url('/assets/ionic-icon-v3/logo-youtube.svg');
  }

  &[name='md-add-circle'] {
    mask-image: url('/assets/ionic-icon-v3/md-add-circle.svg');
  }

  &[name='md-add'] {
    mask-image: url('/assets/ionic-icon-v3/md-add.svg');
  }

  &[name='md-alarm'] {
    mask-image: url('/assets/ionic-icon-v3/md-alarm.svg');
  }

  &[name='md-albums'] {
    mask-image: url('/assets/ionic-icon-v3/md-albums.svg');
  }

  &[name='md-alert'] {
    mask-image: url('/assets/ionic-icon-v3/md-alert.svg');
  }

  &[name='md-american-football'] {
    mask-image: url('/assets/ionic-icon-v3/md-american-football.svg');
  }

  &[name='md-analytics'] {
    mask-image: url('/assets/ionic-icon-v3/md-analytics.svg');
  }

  &[name='md-aperture'] {
    mask-image: url('/assets/ionic-icon-v3/md-aperture.svg');
  }

  &[name='md-apps'] {
    mask-image: url('/assets/ionic-icon-v3/md-apps.svg');
  }

  &[name='md-appstore'] {
    mask-image: url('/assets/ionic-icon-v3/md-appstore.svg');
  }

  &[name='md-archive'] {
    mask-image: url('/assets/ionic-icon-v3/md-archive.svg');
  }

  &[name='md-arrow-back'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-back.svg');
  }

  &[name='md-arrow-down'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-down.svg');
  }

  &[name='md-arrow-dropdown-circle'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-dropdown-circle.svg');
  }

  &[name='md-arrow-dropdown'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-dropdown.svg');
  }

  &[name='md-arrow-dropleft-circle'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-dropleft-circle.svg');
  }

  &[name='md-arrow-dropleft'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-dropleft.svg');
  }

  &[name='md-arrow-dropright-circle'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-dropright-circle.svg');
  }

  &[name='md-arrow-dropright'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-dropright.svg');
  }

  &[name='md-arrow-dropup-circle'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-dropup-circle.svg');
  }

  &[name='md-arrow-dropup'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-dropup.svg');
  }

  &[name='md-arrow-forward'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-forward.svg');
  }

  &[name='md-arrow-round-back'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-round-back.svg');
  }

  &[name='md-arrow-round-down'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-round-down.svg');
  }

  &[name='md-arrow-round-forward'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-round-forward.svg');
  }

  &[name='md-arrow-round-up'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-round-up.svg');
  }

  &[name='md-arrow-up'] {
    mask-image: url('/assets/ionic-icon-v3/md-arrow-up.svg');
  }

  &[name='md-at'] {
    mask-image: url('/assets/ionic-icon-v3/md-at.svg');
  }

  &[name='md-attach'] {
    mask-image: url('/assets/ionic-icon-v3/md-attach.svg');
  }

  &[name='md-backspace'] {
    mask-image: url('/assets/ionic-icon-v3/md-backspace.svg');
  }

  &[name='md-barcode'] {
    mask-image: url('/assets/ionic-icon-v3/md-barcode.svg');
  }

  &[name='md-baseball'] {
    mask-image: url('/assets/ionic-icon-v3/md-baseball.svg');
  }

  &[name='md-basket'] {
    mask-image: url('/assets/ionic-icon-v3/md-basket.svg');
  }

  &[name='md-basketball'] {
    mask-image: url('/assets/ionic-icon-v3/md-basketball.svg');
  }

  &[name='md-battery-charging'] {
    mask-image: url('/assets/ionic-icon-v3/md-battery-charging.svg');
  }

  &[name='md-battery-dead'] {
    mask-image: url('/assets/ionic-icon-v3/md-battery-dead.svg');
  }

  &[name='md-battery-full'] {
    mask-image: url('/assets/ionic-icon-v3/md-battery-full.svg');
  }

  &[name='md-beaker'] {
    mask-image: url('/assets/ionic-icon-v3/md-beaker.svg');
  }

  &[name='md-beer'] {
    mask-image: url('/assets/ionic-icon-v3/md-beer.svg');
  }

  &[name='md-bicycle'] {
    mask-image: url('/assets/ionic-icon-v3/md-bicycle.svg');
  }

  &[name='md-bluetooth'] {
    mask-image: url('/assets/ionic-icon-v3/md-bluetooth.svg');
  }

  &[name='md-boat'] {
    mask-image: url('/assets/ionic-icon-v3/md-boat.svg');
  }

  &[name='md-body'] {
    mask-image: url('/assets/ionic-icon-v3/md-body.svg');
  }

  &[name='md-bonfire'] {
    mask-image: url('/assets/ionic-icon-v3/md-bonfire.svg');
  }

  &[name='md-book'] {
    mask-image: url('/assets/ionic-icon-v3/md-book.svg');
  }

  &[name='md-bookmark'] {
    mask-image: url('/assets/ionic-icon-v3/md-bookmark.svg');
  }

  &[name='md-bookmarks'] {
    mask-image: url('/assets/ionic-icon-v3/md-bookmarks.svg');
  }

  &[name='md-bowtie'] {
    mask-image: url('/assets/ionic-icon-v3/md-bowtie.svg');
  }

  &[name='md-briefcase'] {
    mask-image: url('/assets/ionic-icon-v3/md-briefcase.svg');
  }

  &[name='md-browsers'] {
    mask-image: url('/assets/ionic-icon-v3/md-browsers.svg');
  }

  &[name='md-brush'] {
    mask-image: url('/assets/ionic-icon-v3/md-brush.svg');
  }

  &[name='md-bug'] {
    mask-image: url('/assets/ionic-icon-v3/md-bug.svg');
  }

  &[name='md-build'] {
    mask-image: url('/assets/ionic-icon-v3/md-build.svg');
  }

  &[name='md-bulb'] {
    mask-image: url('/assets/ionic-icon-v3/md-bulb.svg');
  }

  &[name='md-bus'] {
    mask-image: url('/assets/ionic-icon-v3/md-bus.svg');
  }

  &[name='md-cafe'] {
    mask-image: url('/assets/ionic-icon-v3/md-cafe.svg');
  }

  &[name='md-calculator'] {
    mask-image: url('/assets/ionic-icon-v3/md-calculator.svg');
  }

  &[name='md-calendar'] {
    mask-image: url('/assets/ionic-icon-v3/md-calendar.svg');
  }

  &[name='md-call'] {
    mask-image: url('/assets/ionic-icon-v3/md-call.svg');
  }

  &[name='md-camera'] {
    mask-image: url('/assets/ionic-icon-v3/md-camera.svg');
  }

  &[name='md-car'] {
    mask-image: url('/assets/ionic-icon-v3/md-car.svg');
  }

  &[name='md-card'] {
    mask-image: url('/assets/ionic-icon-v3/md-card.svg');
  }

  &[name='md-cart'] {
    mask-image: url('/assets/ionic-icon-v3/md-cart.svg');
  }

  &[name='md-cash'] {
    mask-image: url('/assets/ionic-icon-v3/md-cash.svg');
  }

  &[name='md-chatboxes'] {
    mask-image: url('/assets/ionic-icon-v3/md-chatboxes.svg');
  }

  &[name='md-chatbubbles'] {
    mask-image: url('/assets/ionic-icon-v3/md-chatbubbles.svg');
  }

  &[name='md-checkbox-outline'] {
    mask-image: url('/assets/ionic-icon-v3/md-checkbox-outline.svg');
  }

  &[name='md-checkbox'] {
    mask-image: url('/assets/ionic-icon-v3/md-checkbox.svg');
  }

  &[name='md-checkmark-circle-outline'] {
    mask-image: url('/assets/ionic-icon-v3/md-checkmark-circle-outline.svg');
  }

  &[name='md-checkmark-circle'] {
    mask-image: url('/assets/ionic-icon-v3/md-checkmark-circle.svg');
  }

  &[name='md-checkmark'] {
    mask-image: url('/assets/ionic-icon-v3/md-checkmark.svg');
  }

  &[name='md-clipboard'] {
    mask-image: url('/assets/ionic-icon-v3/md-clipboard.svg');
  }

  &[name='md-clock'] {
    mask-image: url('/assets/ionic-icon-v3/md-clock.svg');
  }

  &[name='md-close-circle'] {
    mask-image: url('/assets/ionic-icon-v3/md-close-circle.svg');
  }

  &[name='md-close'] {
    mask-image: url('/assets/ionic-icon-v3/md-close.svg');
  }

  &[name='md-closed-captioning'] {
    mask-image: url('/assets/ionic-icon-v3/md-closed-captioning.svg');
  }

  &[name='md-cloud-circle'] {
    mask-image: url('/assets/ionic-icon-v3/md-cloud-circle.svg');
  }

  &[name='md-cloud-done'] {
    mask-image: url('/assets/ionic-icon-v3/md-cloud-done.svg');
  }

  &[name='md-cloud-download'] {
    mask-image: url('/assets/ionic-icon-v3/md-cloud-download.svg');
  }

  &[name='md-cloud-outline'] {
    mask-image: url('/assets/ionic-icon-v3/md-cloud-outline.svg');
  }

  &[name='md-cloud-upload'] {
    mask-image: url('/assets/ionic-icon-v3/md-cloud-upload.svg');
  }

  &[name='md-cloud'] {
    mask-image: url('/assets/ionic-icon-v3/md-cloud.svg');
  }

  &[name='md-cloudy-night'] {
    mask-image: url('/assets/ionic-icon-v3/md-cloudy-night.svg');
  }

  &[name='md-cloudy'] {
    mask-image: url('/assets/ionic-icon-v3/md-cloudy.svg');
  }

  &[name='md-code-download'] {
    mask-image: url('/assets/ionic-icon-v3/md-code-download.svg');
  }

  &[name='md-code-working'] {
    mask-image: url('/assets/ionic-icon-v3/md-code-working.svg');
  }

  &[name='md-code'] {
    mask-image: url('/assets/ionic-icon-v3/md-code.svg');
  }

  &[name='md-cog'] {
    mask-image: url('/assets/ionic-icon-v3/md-cog.svg');
  }

  &[name='md-color-fill'] {
    mask-image: url('/assets/ionic-icon-v3/md-color-fill.svg');
  }

  &[name='md-color-filter'] {
    mask-image: url('/assets/ionic-icon-v3/md-color-filter.svg');
  }

  &[name='md-color-palette'] {
    mask-image: url('/assets/ionic-icon-v3/md-color-palette.svg');
  }

  &[name='md-color-wand'] {
    mask-image: url('/assets/ionic-icon-v3/md-color-wand.svg');
  }

  &[name='md-compass'] {
    mask-image: url('/assets/ionic-icon-v3/md-compass.svg');
  }

  &[name='md-construct'] {
    mask-image: url('/assets/ionic-icon-v3/md-construct.svg');
  }

  &[name='md-contact'] {
    mask-image: url('/assets/ionic-icon-v3/md-contact.svg');
  }

  &[name='md-contacts'] {
    mask-image: url('/assets/ionic-icon-v3/md-contacts.svg');
  }

  &[name='md-contract'] {
    mask-image: url('/assets/ionic-icon-v3/md-contract.svg');
  }

  &[name='md-contrast'] {
    mask-image: url('/assets/ionic-icon-v3/md-contrast.svg');
  }

  &[name='md-copy'] {
    mask-image: url('/assets/ionic-icon-v3/md-copy.svg');
  }

  &[name='md-create'] {
    mask-image: url('/assets/ionic-icon-v3/md-create.svg');
  }

  &[name='md-crop'] {
    mask-image: url('/assets/ionic-icon-v3/md-crop.svg');
  }

  &[name='md-cube'] {
    mask-image: url('/assets/ionic-icon-v3/md-cube.svg');
  }

  &[name='md-cut'] {
    mask-image: url('/assets/ionic-icon-v3/md-cut.svg');
  }

  &[name='md-desktop'] {
    mask-image: url('/assets/ionic-icon-v3/md-desktop.svg');
  }

  &[name='md-disc'] {
    mask-image: url('/assets/ionic-icon-v3/md-disc.svg');
  }

  &[name='md-document'] {
    mask-image: url('/assets/ionic-icon-v3/md-document.svg');
  }

  &[name='md-done-all'] {
    mask-image: url('/assets/ionic-icon-v3/md-done-all.svg');
  }

  &[name='md-download'] {
    mask-image: url('/assets/ionic-icon-v3/md-download.svg');
  }

  &[name='md-easel'] {
    mask-image: url('/assets/ionic-icon-v3/md-easel.svg');
  }

  &[name='md-egg'] {
    mask-image: url('/assets/ionic-icon-v3/md-egg.svg');
  }

  &[name='md-exit'] {
    mask-image: url('/assets/ionic-icon-v3/md-exit.svg');
  }

  &[name='md-expand'] {
    mask-image: url('/assets/ionic-icon-v3/md-expand.svg');
  }

  &[name='md-eye-off'] {
    mask-image: url('/assets/ionic-icon-v3/md-eye-off.svg');
  }

  &[name='md-eye'] {
    mask-image: url('/assets/ionic-icon-v3/md-eye.svg');
  }

  &[name='md-fastforward'] {
    mask-image: url('/assets/ionic-icon-v3/md-fastforward.svg');
  }

  &[name='md-female'] {
    mask-image: url('/assets/ionic-icon-v3/md-female.svg');
  }

  &[name='md-filing'] {
    mask-image: url('/assets/ionic-icon-v3/md-filing.svg');
  }

  &[name='md-film'] {
    mask-image: url('/assets/ionic-icon-v3/md-film.svg');
  }

  &[name='md-finger-print'] {
    mask-image: url('/assets/ionic-icon-v3/md-finger-print.svg');
  }

  &[name='md-flag'] {
    mask-image: url('/assets/ionic-icon-v3/md-flag.svg');
  }

  &[name='md-flame'] {
    mask-image: url('/assets/ionic-icon-v3/md-flame.svg');
  }

  &[name='md-flash'] {
    mask-image: url('/assets/ionic-icon-v3/md-flash.svg');
  }

  &[name='md-flask'] {
    mask-image: url('/assets/ionic-icon-v3/md-flask.svg');
  }

  &[name='md-flower'] {
    mask-image: url('/assets/ionic-icon-v3/md-flower.svg');
  }

  &[name='md-folder-open'] {
    mask-image: url('/assets/ionic-icon-v3/md-folder-open.svg');
  }

  &[name='md-folder'] {
    mask-image: url('/assets/ionic-icon-v3/md-folder.svg');
  }

  &[name='md-football'] {
    mask-image: url('/assets/ionic-icon-v3/md-football.svg');
  }

  &[name='md-funnel'] {
    mask-image: url('/assets/ionic-icon-v3/md-funnel.svg');
  }

  &[name='md-game-controller-a'] {
    mask-image: url('/assets/ionic-icon-v3/md-game-controller-a.svg');
  }

  &[name='md-game-controller-b'] {
    mask-image: url('/assets/ionic-icon-v3/md-game-controller-b.svg');
  }

  &[name='md-git-branch'] {
    mask-image: url('/assets/ionic-icon-v3/md-git-branch.svg');
  }

  &[name='md-git-commit'] {
    mask-image: url('/assets/ionic-icon-v3/md-git-commit.svg');
  }

  &[name='md-git-compare'] {
    mask-image: url('/assets/ionic-icon-v3/md-git-compare.svg');
  }

  &[name='md-git-merge'] {
    mask-image: url('/assets/ionic-icon-v3/md-git-merge.svg');
  }

  &[name='md-git-network'] {
    mask-image: url('/assets/ionic-icon-v3/md-git-network.svg');
  }

  &[name='md-git-pull-request'] {
    mask-image: url('/assets/ionic-icon-v3/md-git-pull-request.svg');
  }

  &[name='md-glasses'] {
    mask-image: url('/assets/ionic-icon-v3/md-glasses.svg');
  }

  &[name='md-globe'] {
    mask-image: url('/assets/ionic-icon-v3/md-globe.svg');
  }

  &[name='md-grid'] {
    mask-image: url('/assets/ionic-icon-v3/md-grid.svg');
  }

  &[name='md-hammer'] {
    mask-image: url('/assets/ionic-icon-v3/md-hammer.svg');
  }

  &[name='md-hand'] {
    mask-image: url('/assets/ionic-icon-v3/md-hand.svg');
  }

  &[name='md-happy'] {
    mask-image: url('/assets/ionic-icon-v3/md-happy.svg');
  }

  &[name='md-headset'] {
    mask-image: url('/assets/ionic-icon-v3/md-headset.svg');
  }

  &[name='md-heart-outline'] {
    mask-image: url('/assets/ionic-icon-v3/md-heart-outline.svg');
  }

  &[name='md-heart'] {
    mask-image: url('/assets/ionic-icon-v3/md-heart.svg');
  }

  &[name='md-help-buoy'] {
    mask-image: url('/assets/ionic-icon-v3/md-help-buoy.svg');
  }

  &[name='md-help-circle'] {
    mask-image: url('/assets/ionic-icon-v3/md-help-circle.svg');
  }

  &[name='md-help'] {
    mask-image: url('/assets/ionic-icon-v3/md-help.svg');
  }

  &[name='md-home'] {
    mask-image: url('/assets/ionic-icon-v3/md-home.svg');
  }

  &[name='md-ice-cream'] {
    mask-image: url('/assets/ionic-icon-v3/md-ice-cream.svg');
  }

  &[name='md-image'] {
    mask-image: url('/assets/ionic-icon-v3/md-image.svg');
  }

  &[name='md-images'] {
    mask-image: url('/assets/ionic-icon-v3/md-images.svg');
  }

  &[name='md-infinite'] {
    mask-image: url('/assets/ionic-icon-v3/md-infinite.svg');
  }

  &[name='md-information-circle'] {
    mask-image: url('/assets/ionic-icon-v3/md-information-circle.svg');
  }

  &[name='md-information'] {
    mask-image: url('/assets/ionic-icon-v3/md-information.svg');
  }

  &[name='md-ionic'] {
    mask-image: url('/assets/ionic-icon-v3/md-ionic.svg');
  }

  &[name='md-ionitron'] {
    mask-image: url('/assets/ionic-icon-v3/md-ionitron.svg');
  }

  &[name='md-jet'] {
    mask-image: url('/assets/ionic-icon-v3/md-jet.svg');
  }

  &[name='md-key'] {
    mask-image: url('/assets/ionic-icon-v3/md-key.svg');
  }

  &[name='md-keypad'] {
    mask-image: url('/assets/ionic-icon-v3/md-keypad.svg');
  }

  &[name='md-laptop'] {
    mask-image: url('/assets/ionic-icon-v3/md-laptop.svg');
  }

  &[name='md-leaf'] {
    mask-image: url('/assets/ionic-icon-v3/md-leaf.svg');
  }

  &[name='md-link'] {
    mask-image: url('/assets/ionic-icon-v3/md-link.svg');
  }

  &[name='md-list-box'] {
    mask-image: url('/assets/ionic-icon-v3/md-list-box.svg');
  }

  &[name='md-list'] {
    mask-image: url('/assets/ionic-icon-v3/md-list.svg');
  }

  &[name='md-locate'] {
    mask-image: url('/assets/ionic-icon-v3/md-locate.svg');
  }

  &[name='md-lock'] {
    mask-image: url('/assets/ionic-icon-v3/md-lock.svg');
  }

  &[name='md-log-in'] {
    mask-image: url('/assets/ionic-icon-v3/md-log-in.svg');
  }

  &[name='md-log-out'] {
    mask-image: url('/assets/ionic-icon-v3/md-log-out.svg');
  }

  &[name='md-magnet'] {
    mask-image: url('/assets/ionic-icon-v3/md-magnet.svg');
  }

  &[name='md-mail-open'] {
    mask-image: url('/assets/ionic-icon-v3/md-mail-open.svg');
  }

  &[name='md-mail'] {
    mask-image: url('/assets/ionic-icon-v3/md-mail.svg');
  }

  &[name='md-male'] {
    mask-image: url('/assets/ionic-icon-v3/md-male.svg');
  }

  &[name='md-man'] {
    mask-image: url('/assets/ionic-icon-v3/md-man.svg');
  }

  &[name='md-map'] {
    mask-image: url('/assets/ionic-icon-v3/md-map.svg');
  }

  &[name='md-medal'] {
    mask-image: url('/assets/ionic-icon-v3/md-medal.svg');
  }

  &[name='md-medical'] {
    mask-image: url('/assets/ionic-icon-v3/md-medical.svg');
  }

  &[name='md-medkit'] {
    mask-image: url('/assets/ionic-icon-v3/md-medkit.svg');
  }

  &[name='md-megaphone'] {
    mask-image: url('/assets/ionic-icon-v3/md-megaphone.svg');
  }

  &[name='md-menu'] {
    mask-image: url('/assets/ionic-icon-v3/md-menu.svg');
  }

  &[name='md-mic-off'] {
    mask-image: url('/assets/ionic-icon-v3/md-mic-off.svg');
  }

  &[name='md-mic'] {
    mask-image: url('/assets/ionic-icon-v3/md-mic.svg');
  }

  &[name='md-microphone'] {
    mask-image: url('/assets/ionic-icon-v3/md-microphone.svg');
  }

  &[name='md-moon'] {
    mask-image: url('/assets/ionic-icon-v3/md-moon.svg');
  }

  &[name='md-more'] {
    mask-image: url('/assets/ionic-icon-v3/md-more.svg');
  }

  &[name='md-move'] {
    mask-image: url('/assets/ionic-icon-v3/md-move.svg');
  }

  &[name='md-musical-note'] {
    mask-image: url('/assets/ionic-icon-v3/md-musical-note.svg');
  }

  &[name='md-musical-notes'] {
    mask-image: url('/assets/ionic-icon-v3/md-musical-notes.svg');
  }

  &[name='md-navigate'] {
    mask-image: url('/assets/ionic-icon-v3/md-navigate.svg');
  }

  &[name='md-no-smoking'] {
    mask-image: url('/assets/ionic-icon-v3/md-no-smoking.svg');
  }

  &[name='md-notifications-off'] {
    mask-image: url('/assets/ionic-icon-v3/md-notifications-off.svg');
  }

  &[name='md-notifications-outline'] {
    mask-image: url('/assets/ionic-icon-v3/md-notifications-outline.svg');
  }

  &[name='md-notifications'] {
    mask-image: url('/assets/ionic-icon-v3/md-notifications.svg');
  }

  &[name='md-nuclear'] {
    mask-image: url('/assets/ionic-icon-v3/md-nuclear.svg');
  }

  &[name='md-nutrition'] {
    mask-image: url('/assets/ionic-icon-v3/md-nutrition.svg');
  }

  &[name='md-open'] {
    mask-image: url('/assets/ionic-icon-v3/md-open.svg');
  }

  &[name='md-options'] {
    mask-image: url('/assets/ionic-icon-v3/md-options.svg');
  }

  &[name='md-outlet'] {
    mask-image: url('/assets/ionic-icon-v3/md-outlet.svg');
  }

  &[name='md-paper-plane'] {
    mask-image: url('/assets/ionic-icon-v3/md-paper-plane.svg');
  }

  &[name='md-paper'] {
    mask-image: url('/assets/ionic-icon-v3/md-paper.svg');
  }

  &[name='md-partly-sunny'] {
    mask-image: url('/assets/ionic-icon-v3/md-partly-sunny.svg');
  }

  &[name='md-pause'] {
    mask-image: url('/assets/ionic-icon-v3/md-pause.svg');
  }

  &[name='md-paw'] {
    mask-image: url('/assets/ionic-icon-v3/md-paw.svg');
  }

  &[name='md-people'] {
    mask-image: url('/assets/ionic-icon-v3/md-people.svg');
  }

  &[name='md-person-add'] {
    mask-image: url('/assets/ionic-icon-v3/md-person-add.svg');
  }

  &[name='md-person'] {
    mask-image: url('/assets/ionic-icon-v3/md-person.svg');
  }

  &[name='md-phone-landscape'] {
    mask-image: url('/assets/ionic-icon-v3/md-phone-landscape.svg');
  }

  &[name='md-phone-portrait'] {
    mask-image: url('/assets/ionic-icon-v3/md-phone-portrait.svg');
  }

  &[name='md-photos'] {
    mask-image: url('/assets/ionic-icon-v3/md-photos.svg');
  }

  &[name='md-pie'] {
    mask-image: url('/assets/ionic-icon-v3/md-pie.svg');
  }

  &[name='md-pin'] {
    mask-image: url('/assets/ionic-icon-v3/md-pin.svg');
  }

  &[name='md-pint'] {
    mask-image: url('/assets/ionic-icon-v3/md-pint.svg');
  }

  &[name='md-pizza'] {
    mask-image: url('/assets/ionic-icon-v3/md-pizza.svg');
  }

  &[name='md-plane'] {
    mask-image: url('/assets/ionic-icon-v3/md-plane.svg');
  }

  &[name='md-planet'] {
    mask-image: url('/assets/ionic-icon-v3/md-planet.svg');
  }

  &[name='md-play'] {
    mask-image: url('/assets/ionic-icon-v3/md-play.svg');
  }

  &[name='md-podium'] {
    mask-image: url('/assets/ionic-icon-v3/md-podium.svg');
  }

  &[name='md-power'] {
    mask-image: url('/assets/ionic-icon-v3/md-power.svg');
  }

  &[name='md-pricetag'] {
    mask-image: url('/assets/ionic-icon-v3/md-pricetag.svg');
  }

  &[name='md-pricetags'] {
    mask-image: url('/assets/ionic-icon-v3/md-pricetags.svg');
  }

  &[name='md-print'] {
    mask-image: url('/assets/ionic-icon-v3/md-print.svg');
  }

  &[name='md-pulse'] {
    mask-image: url('/assets/ionic-icon-v3/md-pulse.svg');
  }

  &[name='md-qr-scanner'] {
    mask-image: url('/assets/ionic-icon-v3/md-qr-scanner.svg');
  }

  &[name='md-quote'] {
    mask-image: url('/assets/ionic-icon-v3/md-quote.svg');
  }

  &[name='md-radio-button-off'] {
    mask-image: url('/assets/ionic-icon-v3/md-radio-button-off.svg');
  }

  &[name='md-radio-button-on'] {
    mask-image: url('/assets/ionic-icon-v3/md-radio-button-on.svg');
  }

  &[name='md-radio'] {
    mask-image: url('/assets/ionic-icon-v3/md-radio.svg');
  }

  &[name='md-rainy'] {
    mask-image: url('/assets/ionic-icon-v3/md-rainy.svg');
  }

  &[name='md-recording'] {
    mask-image: url('/assets/ionic-icon-v3/md-recording.svg');
  }

  &[name='md-redo'] {
    mask-image: url('/assets/ionic-icon-v3/md-redo.svg');
  }

  &[name='md-refresh-circle'] {
    mask-image: url('/assets/ionic-icon-v3/md-refresh-circle.svg');
  }

  &[name='md-refresh'] {
    mask-image: url('/assets/ionic-icon-v3/md-refresh.svg');
  }

  &[name='md-remove-circle'] {
    mask-image: url('/assets/ionic-icon-v3/md-remove-circle.svg');
  }

  &[name='md-remove'] {
    mask-image: url('/assets/ionic-icon-v3/md-remove.svg');
  }

  &[name='md-reorder'] {
    mask-image: url('/assets/ionic-icon-v3/md-reorder.svg');
  }

  &[name='md-repeat'] {
    mask-image: url('/assets/ionic-icon-v3/md-repeat.svg');
  }

  &[name='md-resize'] {
    mask-image: url('/assets/ionic-icon-v3/md-resize.svg');
  }

  &[name='md-restaurant'] {
    mask-image: url('/assets/ionic-icon-v3/md-restaurant.svg');
  }

  &[name='md-return-left'] {
    mask-image: url('/assets/ionic-icon-v3/md-return-left.svg');
  }

  &[name='md-return-right'] {
    mask-image: url('/assets/ionic-icon-v3/md-return-right.svg');
  }

  &[name='md-reverse-camera'] {
    mask-image: url('/assets/ionic-icon-v3/md-reverse-camera.svg');
  }

  &[name='md-rewind'] {
    mask-image: url('/assets/ionic-icon-v3/md-rewind.svg');
  }

  &[name='md-ribbon'] {
    mask-image: url('/assets/ionic-icon-v3/md-ribbon.svg');
  }

  &[name='md-rose'] {
    mask-image: url('/assets/ionic-icon-v3/md-rose.svg');
  }

  &[name='md-sad'] {
    mask-image: url('/assets/ionic-icon-v3/md-sad.svg');
  }

  &[name='md-school'] {
    mask-image: url('/assets/ionic-icon-v3/md-school.svg');
  }

  &[name='md-search'] {
    mask-image: url('/assets/ionic-icon-v3/md-search.svg');
  }

  &[name='md-send'] {
    mask-image: url('/assets/ionic-icon-v3/md-send.svg');
  }

  &[name='md-settings'] {
    mask-image: url('/assets/ionic-icon-v3/md-settings.svg');
  }

  &[name='md-share-alt'] {
    mask-image: url('/assets/ionic-icon-v3/md-share-alt.svg');
  }

  &[name='md-share'] {
    mask-image: url('/assets/ionic-icon-v3/md-share.svg');
  }

  &[name='md-shirt'] {
    mask-image: url('/assets/ionic-icon-v3/md-shirt.svg');
  }

  &[name='md-shuffle'] {
    mask-image: url('/assets/ionic-icon-v3/md-shuffle.svg');
  }

  &[name='md-skip-backward'] {
    mask-image: url('/assets/ionic-icon-v3/md-skip-backward.svg');
  }

  &[name='md-skip-forward'] {
    mask-image: url('/assets/ionic-icon-v3/md-skip-forward.svg');
  }

  &[name='md-snow'] {
    mask-image: url('/assets/ionic-icon-v3/md-snow.svg');
  }

  &[name='md-speedometer'] {
    mask-image: url('/assets/ionic-icon-v3/md-speedometer.svg');
  }

  &[name='md-square-outline'] {
    mask-image: url('/assets/ionic-icon-v3/md-square-outline.svg');
  }

  &[name='md-square'] {
    mask-image: url('/assets/ionic-icon-v3/md-square.svg');
  }

  &[name='md-star-half'] {
    mask-image: url('/assets/ionic-icon-v3/md-star-half.svg');
  }

  &[name='md-star-outline'] {
    mask-image: url('/assets/ionic-icon-v3/md-star-outline.svg');
  }

  &[name='md-star'] {
    mask-image: url('/assets/ionic-icon-v3/md-star.svg');
  }

  &[name='md-stats'] {
    mask-image: url('/assets/ionic-icon-v3/md-stats.svg');
  }

  &[name='md-stopwatch'] {
    mask-image: url('/assets/ionic-icon-v3/md-stopwatch.svg');
  }

  &[name='md-subway'] {
    mask-image: url('/assets/ionic-icon-v3/md-subway.svg');
  }

  &[name='md-sunny'] {
    mask-image: url('/assets/ionic-icon-v3/md-sunny.svg');
  }

  &[name='md-swap'] {
    mask-image: url('/assets/ionic-icon-v3/md-swap.svg');
  }

  &[name='md-switch'] {
    mask-image: url('/assets/ionic-icon-v3/md-switch.svg');
  }

  &[name='md-sync'] {
    mask-image: url('/assets/ionic-icon-v3/md-sync.svg');
  }

  &[name='md-tablet-landscape'] {
    mask-image: url('/assets/ionic-icon-v3/md-tablet-landscape.svg');
  }

  &[name='md-tablet-portrait'] {
    mask-image: url('/assets/ionic-icon-v3/md-tablet-portrait.svg');
  }

  &[name='md-tennisball'] {
    mask-image: url('/assets/ionic-icon-v3/md-tennisball.svg');
  }

  &[name='md-text'] {
    mask-image: url('/assets/ionic-icon-v3/md-text.svg');
  }

  &[name='md-thermometer'] {
    mask-image: url('/assets/ionic-icon-v3/md-thermometer.svg');
  }

  &[name='md-thumbs-down'] {
    mask-image: url('/assets/ionic-icon-v3/md-thumbs-down.svg');
  }

  &[name='md-thumbs-up'] {
    mask-image: url('/assets/ionic-icon-v3/md-thumbs-up.svg');
  }

  &[name='md-thunderstorm'] {
    mask-image: url('/assets/ionic-icon-v3/md-thunderstorm.svg');
  }

  &[name='md-time'] {
    mask-image: url('/assets/ionic-icon-v3/md-time.svg');
  }

  &[name='md-timer'] {
    mask-image: url('/assets/ionic-icon-v3/md-timer.svg');
  }

  &[name='md-train'] {
    mask-image: url('/assets/ionic-icon-v3/md-train.svg');
  }

  &[name='md-transgender'] {
    mask-image: url('/assets/ionic-icon-v3/md-transgender.svg');
  }

  &[name='md-trash'] {
    mask-image: url('/assets/ionic-icon-v3/md-trash.svg');
  }

  &[name='md-trash-outline'] {
    mask-image: url('/assets/img/icons/trash-icon-outline.svg');
  }

  &[name='md-trending-down'] {
    mask-image: url('/assets/ionic-icon-v3/md-trending-down.svg');
  }

  &[name='md-trending-up'] {
    mask-image: url('/assets/ionic-icon-v3/md-trending-up.svg');
  }

  &[name='md-trophy'] {
    mask-image: url('/assets/ionic-icon-v3/md-trophy.svg');
  }

  &[name='md-umbrella'] {
    mask-image: url('/assets/ionic-icon-v3/md-umbrella.svg');
  }

  &[name='md-undo'] {
    mask-image: url('/assets/ionic-icon-v3/md-undo.svg');
  }

  &[name='md-unlock'] {
    mask-image: url('/assets/ionic-icon-v3/md-unlock.svg');
  }

  &[name='md-videocam'] {
    mask-image: url('/assets/ionic-icon-v3/md-videocam.svg');
  }

  &[name='md-volume-down'] {
    mask-image: url('/assets/ionic-icon-v3/md-volume-down.svg');
  }

  &[name='md-volume-mute'] {
    mask-image: url('/assets/ionic-icon-v3/md-volume-mute.svg');
  }

  &[name='md-volume-off'] {
    mask-image: url('/assets/ionic-icon-v3/md-volume-off.svg');
  }

  &[name='md-volume-up'] {
    mask-image: url('/assets/ionic-icon-v3/md-volume-up.svg');
  }

  &[name='md-walk'] {
    mask-image: url('/assets/ionic-icon-v3/md-walk.svg');
  }

  &[name='md-warning'] {
    mask-image: url('/assets/ionic-icon-v3/md-warning.svg');
  }

  &[name='md-watch'] {
    mask-image: url('/assets/ionic-icon-v3/md-watch.svg');
  }

  &[name='md-water'] {
    mask-image: url('/assets/ionic-icon-v3/md-water.svg');
  }

  &[name='md-wifi'] {
    mask-image: url('/assets/ionic-icon-v3/md-wifi.svg');
  }

  &[name='md-wine'] {
    mask-image: url('/assets/ionic-icon-v3/md-wine.svg');
  }

  &[name='md-woman'] {
    mask-image: url('/assets/ionic-icon-v3/md-woman.svg');
  }





}
