// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "@fontsource/inter/300.css";   // Light
@import "@fontsource/inter/400.css";   // Regular
@import "@fontsource/inter/500.css";   // Medium
@import "@fontsource/inter/700.css";   // Bold
@import "@fontsource/dancing-script/400.css";  // Regular

html {
  font-family: "Inter", Arial, sans-serif;
  font-size: 1.4rem;
}

@import 'theme/custom-icons.scss';
@import 'theme/slides-elements.scss';
@import 'theme/round-button.scss';
@import 'theme/tidy-card.scss';
@import 'shared/theme-components/tidy-card/tidy-card.directive.scss';
@import 'shared/theme-components/tidy-big-icon/tidy-big-icon.component.scss';
@import 'theme/variables.scss';
@import 'shared/theme-components/tidy-label/tidy-label.directive.scss';
@import 'shared/theme-components/tidy-link/tidy-link.directive.scss';
@import 'theme/ui-v2.scss';
@import 'theme/icon-todo.scss';
@import 'theme/todos-form.scss';
@import 'shared/theme-components/tidy-card-input/tidy-card-input.directive.scss';

.align-input{
  font-size: 16px;
  margin: 10px 0 14px 0;
  font-family: "Inter", Arial, sans-serif;
  color: #333;
  position: relative;
  label{
    display:block;
    margin-bottom: 10px;
  }
  input{
    border: 1px solid rgb(204, 204, 204);
    display: block;
    padding: 9px;
    height: 34px;
    border-radius: 5px;
    width: 100%;
  }
  &.average-nightly{
    input{
      padding-left: 20px;
    }
    .dollar-sign{
      position: absolute;
      bottom: 9px;
      left: 7px;
    }
  }
}

.tidy-content{
  font-size: 16px;

  &.tidy-box {
    .tidy-booking-title {
      font-weight: bold;
      text-align: left;
    }
  }
}

.tidy-button{
  display: block;
  width: 100%;
  margin: 15px 2px 2px 2px;
  background: var(--ion-color-tidy_green);
  font-family: var(--title-booking);
  font-weight: 500;
  letter-spacing: 1.2px;
  display: block;
  width: 100%;
  color: #fff;
  font-size: 20px;
  padding: 2px 10px 12px 10px;
  box-shadow:  0 3px 1px -2px rgba(0,0,0,0.2),0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12);
  position: relative;
  &:hover,
  &:focus {
    background: var(--ion-color-tidy_green);
  }
  input[type="radio"]{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  label{
    top: 0;
    left: 0;
  }
}

.tidy-content{
  font-size: 16px;

  &.tidy-box {
    .tidy-booking-title {
      font-weight: bold;
      text-align: left;
    }
  }
}

.phone-icon {
  width: 24px;
}

ion-icon {
  font-size: 22px;
  margin-right: 10px;
}

ion-fab-button{
  ion-icon{
    margin-right: 0;
  }
}
ion-button{
  &:hover{
    background: inherit;
  }
}
ion-button.remove-default-styles{
  --background: transparent;
  --box-shadow: none;
  --border: none;
  --padding-start: 0;
  --paddint-top: 0;
  --padding-bottom: 0;
  margin: 0;
  --padding-end: 0;
}

.align-reschedule-text{
  margin: 0px 0 0 37px;
}

.bt-tidy-todo {
  background: transparent;
  color: #4AAAC4;
  margin: 0.2rem 0.2rem 0.2rem 0;
  padding-left: 0;
  text-decoration: underline;
  font-size: 1.7rem;
}

//ion-header ion-toolbar{
//  --min-height: auto;
//  padding: 5px 0px;
//  ion-button{
//    background: none;
//  }
//}

//ion-header {
//  ion-title {
//    height: 48px;
//  }
//}


@media screen and (max-width: 800px){
  .plus-button{
    right: 70px;
    bottom: 10%;
  }
}

@media screen and (min-width: 800px){
  .plus-button{
    right: calc(-268px + 50%);
  }
}


.changes-saved{
  font-size: 14px;
}

ion-title{
  font: 25px var(--title-booking);
  font-weight: 400;
  margin-top: 5px;
  text-align: center;
  position: relative;
}


@media print and (min-width: 10px){
  .tidy-content{
    max-width: inherit !important;
    width: 100% !important;
  }
  #loggedInMenu {
    visibility: hidden !important;
    display: none !important;
  }
  .custom-back-button{
    display: none !important;
  }
}

@media screen and (max-width: 1000px) {
  ion-title{
    font-size: 18px;
  }
}


.text-round-button {
  display: block;
  margin: 10px 0 15px 0;
}

.ion-color-link {
  color:  var(--ion-color-link);
  font-size: 1.6rem;
  text-transform: none;
  font-weight: normal;
  --box-shadow: none;
  text-decoration: underline;
  --padding-start: 0;
  background: transparent;
  &:hover{
    background: transparent;
  }
}



ion-button {
  font-size: 1.6rem;
  text-transform: none;
}

.important-task-button {
  padding-top: 20px;
  min-width: 30px;
  margin: auto;
  position: absolute;
  left: 10px;
  top: 30px;
  z-index: 999;
}

ion-label{
  white-space: normal !important;
  color: rgb(153, 153, 153) !important;
}


@media screen and (min-width: 1000px) {
  .toast-new-ui{
    margin-left: 150px;
  }
}

.ion-color-tidy_blue {
  background: var(--ion-color-tidy_blue);
  &:hover{
    background: var(--ion-color-tidy_blue);
  }
}

.ion-color-tidy_yellow {
  background: var(--ion-color-tidy_yellow);
  &:hover{
    background: var(--ion-color-tidy_yellow);
  }
}

.ion-color-tidy_green {
  background: var(--ion-color-tidy_green);
  &:hover{
    background: var(--ion-color-tidy_green);
  }
}

.ion-color-tidy_light_green {
  background: #D3EEE9;
  &:hover{
    background: #D3EEE9;
  }
}

.ion-color-black {
  background: var(--ion-color-black);
  color: #fff;
  &:hover{
    background: var(--ion-color-black);
  }
}


.form-row {
  background: #FFFFFF;
  border: 1px solid #dedede;
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0 10px 0;
}


.ion-color-todos_tidy_green {
  background: var(--ion-color-tidy_green);
  border-radius: 50px;
  ion-icon{
    color: white;
  }
  &.fab-button-close-active {
    background: var(--ion-color-tidy_black);
    &:hover{
      background: var(--ion-color-tidy_black);
    }
  }
  &:hover{
    background: var(--ion-color-tidy_green);
  }
}

.tidy-todos-actions {
  .fab-button-close-active {
    color: white;
    --background: var(--ion-color-tidy_black);
    &:hover{
      --background-hover: var(--ion-color-tidy_black);
    }
  }
  ion-fab-list[side=top] {
    right: 0;
  }
  .fab-button-in-list{
    background-color: var(--ion-color-tidy_black);
    --background: var(--ion-color-tidy_black);
    --color: white;
    --border-radius: 0;
    border-radius: 0px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.14), 0 4px 5px rgba(0, 0, 0, 0.1);
    color: white;
    font-size: 14px;
    height: 30px;
    width: 130px;
    &:hover{
      --background-hover:  var(--ion-color-tidy_black);
    }
  }
}

ion-select{
  background: transparent;
  &:hover{
    background: transparent;
  }
}

ion-item{
  --background-hover: transparent;
}

.ion-color-tidy_black {
  background: var(--ion-color-tidy_black);
  &:hover{
    background: var(--ion-color-tidy_black);
  }
}

.back-button-icon-md{
  font-size: 20px;
}
p{
  font-size: 1.9rem;
}

.tidy-list {

  background-color: white;
  border: 2px solid var(--ion-color-tidy_black) ;
  margin: 0;
  padding: 4px;
  ion-list-header.item {
    background-color: white;
    border: 0;
    color: var(--ion-color-tidy_black) ;
    position: relative;
    padding-bottom: 0;
    font-family: var(--title);
    font-size: 2.5rem;
    &.has-icon {
      padding-left: 58px;
    }
    &::before {
      content: "";
      background-repeat: no-repeat;
      height: 60px;
      left: 16px;
      position: absolute;
      top: 13px;
    }
  }
  .ion-list-inner {
    border: 1px solid var(--ion-color-tidy_black) ;
  }
  .item {
    background-color: transparent;
    overflow: visible;
    .note {
      color: var(--ion-color-caption_dark);
    }
    ion-label {
      line-height: 120%;
      margin-bottom: 15px;
    }
    &:last-child {
      .item-inner {
        border-bottom: 0;
      }
    }
    &.activated {
      background-color: var(--ion-light-green);
    }
    &.item-dont {
      color: var(--ion-color-notes);
      strong {
        font-family: var(--title);
        text-transform: uppercase;
      }
    }
    ion-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        border-radius: 0;
      }
    }
  }
  +ion-list {
    margin: 16px 0 0;
    ion-list-header {
      margin-top: 0 !important;
    }
  }
}




@media screen and (min-width: $break-large) {
  router-outlet{
    ion-content {
      --padding-top: 0 !important;
      --padding-bottom: 0 !important;
      --background: transparent;
      background: transparent;
    }
  }
  .tidy-content{
    left: 9.5%;
    z-index:2;
  }
  .menuDisabled{
    .tidy-content{
      left: 0;
    }
  }
  .tidy-content.tidy-box{
    left: 0;
  }
}



.logo-menu-bar {
  text-align: left;
  margin-top: 15px;
  img {
    width: 65%;
  }
}

.hide{
  display: none !important;
}

.hideNavBar {
  ion-header {
    display: none !important;
  }
  nav-bar-desktop {
    display: block !important;
  }
}

.showNavBar {
  ion-header {
    display: block !important;
  }
  nav-bar-desktop {
    display: none !important;
  }
}

.cleaning-skip{
  p{
    color: #222;
  }
}

.activeHighlight {

  ion-label {
    font-weight: bold;
  }
}

iframe {
  height: 100%;
  width: 100%;
}

textarea {
  resize: none;
}

.button-text.sc-ion-back-button-ios{
  display: none;
}

ion-toolbar {
  min-height: 4rem;
}

.toolbar[tidy_black] .toolbar-background {
  background: #000;
}

img+h2 {
  margin-top: 1rem;
}

h2 {
  span {
    font-size: 3rem;
  }
}


ion-radio-group{
  ion-label{
    font-weight: 300;
  }
}

.button-fab {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.45);
}

.item {
  color: var(--ion-color-text);
  --color: inherit;
  --color-hover: inherit;
}

.customer-info {
  font: 1.6rem var(--title-booking);
  margin: 10px 0 0 3px;
  .address-name {
    font-weight: 300;
    line-height: 20px;
  }
  ion-row:first-child {
    font-size: 16px;
    font-weight: bold;
  }
}

.select-container ion-col, .select-container{
  margin: 0;
  padding: 0;
}

.tidy-cleaning-title {
  font-family: Georgia, Times, "Times New Roman", serif;
  text-transform: uppercase;
  font-size: 2.6rem;
}

app-billing-history, cleanings-history-page{
  .tidy-select {
    float: right;
    font-size: 14px;
    ion-select {
      max-width: max-content;
    }
  }
}

.tidy-cleaning-navbar {
  background-color: transparent;
  box-shadow: none;
  margin: 0;
  text-align: center;
  width: 100%;
  ion-card-header {
    font-size: 2.6rem;
    font-family: var(--title);
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    +p {
      font-size: 1.6rem;
      margin: 2rem 0;
      &.text-left {
        text-align: left;
      }
    }
  }
  ion-card-content {
    color: var(--ion-color-black);
    font-size: 2rem;
    font-family: var(--title);
    min-height: 16px;
    +p {
      font-size: 1.6rem;
      margin: 2rem 0;
    }
    ~iframe {
      margin-bottom: 2rem;
    }
  }
  &.tidy-booking-title {
    ion-card-content {
      padding: 0;
    }
    &.feedback-container {
      text-align: center;
      padding: 100px 0 10px;
      .feedback-title {
        font-size: 2rem;
        position: relative;
        width: 100%;
        .iteme {
          height: 1px;
          background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
          height: 1px;
          position: absolute;
          bottom: -1rem;
          width: 90%;
          left: 0;
          margin: 0 5%;
        }
      }
    }
  }
}

.logo-white-header {
  width: 32px;
  position: relative;
  top: 1px;
  margin-left: 10px;
}

ion-button {
  &.button-clear-md-link {
    background-color: transparent;
    text-decoration: underline;
    padding: 0;
    margin: 0;
    height: auto;
    font-size: 1.6rem;
    font-weight: normal;
    .button-inner {
      text-indent: 1px;
    }



    &:hover,
    &:focus {
      background-color: transparent;
    }
    &.add-home-closing {
      margin-top: 1.5rem;
    }
  }
  &.button-md-black {
    ion-spinner {
      * {
        stroke: white;
      }
      &.spinner-circles circle,
      &.spinner-bubbles circle,
      &.spinner-dots circle {
        fill: white;
      }
    }
  }
}

ion-fab {
  position: fixed;
}

ion-list {
  &.suggested-items {
    border-color: var(--ion-color-default_border);
    border-width: 0 1px;
    border-style: solid;
    margin-bottom: 0;
    margin-top: 10px;
    >.item {
      &:first-child {
        border-top-color: var(--ion-color-default_border);
      }
      &:last-child {
        border-bottom-color: var(--ion-color-default_border);
      }
    }
    ion-item {
      background-color: white;
      padding-left: 0;
      .item-inner {
        padding-left: 8px;
      }
      ion-label {
        font-size: 1.25rem;
      }
    }
  }
  &.select-list {
    background-color: white;
    margin: 15px 1px;
    >ion-item {
      background-color: white;
      border: 1px solid var(--ion-color-tidy_black);
      padding-left: 8px;
      min-height: 3.8rem;
      ion-label {
        margin-top: 9px;
        margin-bottom: 10px;
      }
      ion-select {
        padding-top: 9px;
        padding-bottom: 10px;
      }
    }
  }
}

.sub-description, .tidy-cleaning-navbar ion-card-content.sub-description{
  font-family: var(--title-booking);
  padding: 10px 20px 0 20px;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  color: #333333;
  line-height: 17px;
  margin-top: 5px;
}

ion-checkbox{
  width: 18px;
  height: 18px;
  --border-radius: 0;
  margin: 5px 9px 0 0;
  align-self: flex-start;
}

.radio-icon {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 50%;
  border-width: var(--border-width);
  border-style: var(--border-style);
  border-color: var(--color);
}

ion-radio{
  &.radio-checked{
    border: 2px solid #41CAB7;
  }
  --border-width: 0px;
  border: 2px solid rgb(190, 191, 192);
  border-radius: 50%;
  width: 22px;
  height: 20px;
  --color-checked: #41CAB7;
}

ion-checkbox{
  --border-color: rgb(190, 191, 192);
  border-color: rgb(190, 191, 192);
}

.checkbox-description{
  font-size: 12px;
  color: #333;
  font-weight: 300;
}

.label-link {
  display: flex;
  justify-content: space-between;

  span.bundle,
  tidy-label {
    max-width: 170px;
    font-size: 15px;
  }
}

.checkbox-checked {
  --background-checked: #41CAB7;
  --border-color-checked: #41CAB7;
  --border-color: #41CAB7;
}

ion-item{
  --highlight-color-valid: none;
  --inner-border-width: 0;
  --min-height: 30px;
}


page-sign-up, page-address, page-confirm, page-cancel-cleanings, page-what-is-your-issue{
  form{
    position: relative;
  }
  ion-label{
    font-size: 20px !important;
    color: #333!important;
    margin-bottom: 5px !important;
  }
  ion-input,
  .field-align,
  ion-textarea {
    border: 1px solid #ccc;
  }
}

.description-text{
  padding: 0 10px;
  margin: 0 0 20px 0;
  font-family: var(--title-booking);
  text-align: center;
  font-size: 16px;
  color: #333;
  font-weight: 300;
}

form {
  ion-item{
    padding-left: 0;
    --padding-start: 0;
    --padding-left: 0;
    --border-width: 0;
    --background: transparent;
    --highlight-color-invalid: none;
  }

  ion-input{
    --padding-end: 8px;
    --padding-start: 8px;
    --padding-top: 2px;
    --padding-bottom: 2px;
  }
  .item {
    background-color: transparent;
    padding-left: 0;
    .item-inner {
      padding-right: 0;
    }
    &.item-input {
      .item-inner {
        border-bottom: 0;
        padding-right: 0;
      }
    }
  }
  .item-input::after {
    left: 1px;
    right: 1px;
  }
  >ion-label {
    font-size: 15px !important;
    color: var(--ion-color-caption);
  }
  ion-card {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  ion-input {
    border-radius: 5px;
    text-indent: 5px;
  }
  ion-input,
  .field-align,
  ion-textarea {
    --background: white !important;
    --border: 1px solid var(--ion-color-default_border);
    --font-size: 1.4rem;
    margin: 10px 1px 0;
    --padding-end: 8px;
    --padding-start: 8px;
    --padding-top: 2px;
    --padding-bottom: 2px;
    width: calc(100% - 2px);
  }
  ion-button[type=submit] {
    margin-top: 15px;
  }
  .todo-buttons {
    button {
      width: 30%;
      +button {
        margin-left: 2%;
        width: 65%;
      }
    }
  }
}

.label-ios[stacked],
.label-md[stacked] {
  font-size: 1.6rem;
}

p[danger],
.tidy-alert {
  background-color: var(--ion-color-alert);
  color: var(--ion-color-text);
  font-size: 1.6rem;
  margin: 10px 0 0;
  padding: 10px;
  text-align: center;
}

// Radio & Checkbox buttons
.item-ios {
  ion-radio {
    background-color: #FFF;
    border-radius: 50%;
    border: 1px solid var(--ion-color-default_border);
    height: 25px;
    margin-right: 10px;
    text-align: center;
    width: 25px;
    &[item-left] {
      margin-left: 0;
      margin-right: 10px;
    }
    .radio-icon {
      width: 100%;
    }
  }
  &.item-radio-checked {
    ion-radio {
      background-color: var(--ion-color-radio_checkbox_color);
      border-color: var(--ion-color-radio_checkbox_color);
    }
    ion-label {
      color: var(--ion-color-text);
    }
    .radio-checked {
      .radio-inner {
        border-color: white;
        border-right-width: 3px;
        border-bottom-width: 3px;
        height: 14px;
        left: 8px;
        top: 3px;
        width: 8px;
      }
    }
  }
}

.item-md {
  .radio-md {
    .radio-icon {
      background-color: white;
      border-color: var(--ion-color-default_border);
      height: 26px;
      width: 26px;
    }
    .radio-checked {
      border-color: var(--ion-color-radio_checkbox_color);
    }
    .radio-inner {
      background-color: var(--ion-color-radio_checkbox_color);
      top: 4px;
      left: 4px;
      height: 14px;
      width: 14px;
    }
  }
}

.item-radio-checked.item-md {
  ion-label {
    color: var(--ion-color-text);
  }
}

.item-checkbox {
  ion-checkbox, ion-radio{
    .checkbox-icon {
      background-color: #FFF;
      border-color: var(--ion-color-default_border);
      border-radius: 4px;
      height: 25px;
      width: 25px;
    }
  }
  &.item-checkbox-checked {
    .checkbox-checked {
      background-color: var(--ion-color-radio_checkbox_color);
      border-color: var(--ion-color-radio_checkbox_color);
      .checkbox-inner {
        border-color: #FFF;
        border-right-width: 3px;
        border-bottom-width: 3px;
        height: 14px;
        left: 8px;
        top: 3px;
        width: 8px;
      }
    }
  }
}

.hidden {
  display: none;
}

ion-item {
  +.button {
    margin-top: 24px;
  }
}

ion-card p.form-description {
  text-align: left;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}

.checkbox-group {
  position: relative;
  ion-item {
    position: static;
    font-size: 0.9em;
    height: 100%;
  }
  >ion-label {
    color: var(--ion-color-radio_group_grid_label);
    padding-left: 6px;
  }
  ion-grid {
    padding: 0;
  }
  .item-block {
    display: inline;
    &.item-checkbox-checked {
      .item-inner {
        background-color: var(--ion-color-selected_item);
        border: 1px solid var(--ion-color-tidy_black);
        font-weight: normal;
        .input-wrapper {
          ion-label {
            color: var(--ion-color-text);
          }
        }
      }
    }
    ion-checkbox {
      .checkbox-icon {
        display: none;
      }
    }
    .item-inner {
      background-color: white;
      padding-right: 0;
      border: 1px solid var(--ion-color-tidy_black);
      border-radius: 6px;
      font-weight: 300;
      .input-wrapper {
        text-align: center;
        ion-label {
          color: var(--ion-color-radio_group_grid_label);
          margin-right: 0;
        }
      }
    }
  }
}

ion-list.radio-group-grid {
  >ion-label {
    color: var(--ion-color-radio_group_grid_label);
    padding-left: 6px;
  }
  ion-grid {
    padding: 0;
  }
  ion-item{
    background-color: white;
    padding-right: 0;
    border: 1px solid var(--ion-color-black) !important;
    color: var(--ion-color-caption_dark);
    border-radius: 4px;
    font-weight: 300;
    .radio {
      background: white;
      border-color: white;
      float: left;
      margin-left: 2px;
      .radio-icon {
        background-color: white;
        border-color: white;
        height: 20px;
        width: 20px;
        .radio-inner {
          background-color: white;
        }
      }
    }
    .input-wrapper {
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
      ion-label {
        color: var(--ion-color-black);
        font-weight: 300;
        margin-right: 0;
      }
    }
  }
  .item-block {
    &.item-radio-checked {
      background-color: var(--ion-color-black);
      border-radius: 4px;
      box-shadow: 0 2px 3px var(--ion-color-tidy_black_alpha);
      .item-inner {
        background-color: var(--ion-color-selected_item);
        color: var(--ion-color-text);
        padding-right: 0;
        .input-wrapper {
          ion-label {
            font-weight: normal;
          }
        }
        .radio {
          background-color: var(--ion-color-selected_item);
          border-color: var(--ion-color-selected_item);
          .radio-checked {
            background-color: var(--ion-color-selected_item);
            border-color: var(--ion-color-selected_item);
            .radio-inner {
              background-color: var(--ion-color-selected_item);
              border-color: var(--ion-color-selected_item);
            }
          }
        }
      }
    }
    @media screen and (min-width: 1000px) {
      min-height: 6rem;
      .label {
        margin-top: 22px;
        margin-bottom: 16px;
      }
    }
  }
}

.image-card {
  width: 80%;
  margin: 1rem auto 0;
}

.text-lowercase {
  text-transform: lowercase;
}

.label-checkbox {
  color: var(--ion-color-input_color);
}

.back-button-text-ios {
  visibility: hidden;
}

.swiper-container-horizontal {
  >.swiper-pagination {
    top: calc(100% - 50px);
  }
}

.swiper-pagination-bullet {
  background-color: var(--ion-color-swiper_pagination);
  border: 1px solid var(--ion-color-default_border);
  height: 12px;
  opacity: 1;
  width: 12px;
  &.swiper-pagination-bullet-active {
    background: var(--ion-color-swiper_pagination_active);
    border-color: var(--ion-color-swiper_pagination_active);
  }
}

.item-md.item-input .item-inner {
  border-bottom-color: transparent !important;
  box-shadow: none !important;
}

p.card-description {
  color: var(--ion-color-tidy_black);
  font-size: 1.7rem;
}

.bt-small-h {
  height: 4rem;
}

.title-form {
  font-size: 2rem;
  text-align: left;
  margin-bottom: 1rem;
}

.big-textrea {
  height: 100px;
}

ion-col[offset-40] {
  margin-left: 40%;
}

p.today-cleaning {
  font-size: 1.7rem;
  color: var(--ion-color-tidy_green);
  margin-top: 0.2rem;
}

.default-textarea {
  min-height: 10rem;
}

.form-text {
  color: var(--ion-color-tidy_black);
  font-size: 1.7rem;
}

.problem-question {
  padding-bottom: 0;
  display: block;
  text-align: center;
  p {
    font-size: 1.7rem;
  }
}

.problem-card {
  .cleaning-details {
    text-align: center;
  }
  .bt-container-list {
    text-align: center;
    margin-bottom: 1rem;
  }
  .contact-hk-container {
    text-align: left;
  }
  .problem-question {
    padding-bottom: 0;
  }
}

.cleaning-regular-padding {
  padding: 10px 0 19px 0;
}

@media screen and (max-width: $break-large) {
  ion-content{
    --background: transparent;
    background: #FCF7F0;
  }
  .grid-basic-page {
    .cleaning-type-options.chosen-plan {
      .plan-details {
        font-size: 1.25rem;
      }
    }
  }
}

@media screen and (max-width: 1000px) {

  ion-card-content{
    padding: 0 !important;
  }

  .platform-core {
    ion-header {
      .back-button.bar-button {
        position: absolute;
        z-index: 5;
      }
      .title {
        text-align: center;
      }
    }
  }
  ion-toolbar {
    .back-button {
      position: absolute;
      top: 0;
      margin: 0;
      padding-top: 5px;
      padding-right: 0;
      &:hover {
        background-color: transparent;
      }
      +.title {
        margin-left: 50px;
      }
    }
  }
  .grid-basic-page {
    .cleaning-type-options.chosen-plan {
      ion-col:nth-child(2n) {
        flex: 0 0 65%;
        max-width: 65%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}


ion-input,
ion-textarea {
  --highlight-color-focused: none;
  border-radius: 2px;
  margin-top: 5px;
  --padding-end: 8px !important;
  --padding-start: 8px !important;
}



@media screen and (min-width: $break-large) {

  body,
  ion-nav {
    overflow: visible;
  }
  form {
    ion-input,
    ion-textarea {
      border-radius: 2px;
      margin-top: 5px;
    }

    .item.item-label-stacked {
      .label {
        color: var(--ion-color-black);
        font-weight: 300;
        margin-top: 15px;
      }
      .text-input {
        color: var(--ion-color-black);
        font: 300 1.6rem var(--title-booking);
        margin-bottom: 4px;
        margin-top: 4px;
        text-indent: 1px;
      }
    }
    .item-md {
      .radio-md[item-left] {
        margin-right: 10px;
        margin-top: 12px;
      }
    }
  }
  .ion-page .content[padding] {
    padding: 0;
  }
  .ion-page {
    transform: none !important;
    transition: none !important;
    .content {
      padding: 0;
      .scroll-content {
        background: transparent;
        height: 100%;
        padding: 0 0 20px;
      }
      .fixed-content,
      .scroll-content {
        margin-top: 0 !important;
      }
    }
    .todo-actions-overlay {
      display: none;
    }
    .iframe-page {
      iframe {
        margin-bottom: -4px;
        margin-top: 40px;
      }
      .scroll-content {
        padding-bottom: 0;
      }
    }
  }
  page-sign-up,
  page-address {
    .item {
      &:first-child {
        .input-wrapper {
          .label {
            margin-top: 0;
          }
        }
      }
    }
  }
  page-sign-up {
    .toolbar-wide.toolbar-thick {
      .back-button.show-back-button {
        top: 4px;
      }
    }
  }
  page-contact {
    .tidy-content {
      &.tidy-box {
        .tidy-booking-title {
          margin-bottom: 1.5rem;
          ion-col {
            padding: 0;
          }
        }
      }
    }
  }
  page-backup-time {
    .select-list {
      display: inline-block;
      margin: 0 2% 0 0;
      width: 45%;
    }
  }
  page-my-house {
    ion-button {
      margin-top: 2.5rem;
    }
  }
  page-booking-schedule-plan {
    .grid-basic-page .cleaning-type-options.chosen-plan ion-col {
      padding: 0;
    }
  }
  .tidy-content {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 4px;
    margin: 45px auto 0 !important;
    padding: 0px;
  }
  .select-alert.alert-md {
    .alert-radio-group {
      overflow-y: scroll;
    }
  }
  ion-grid.no-padding {
    padding: 0;
    ion-row {
      ion-col {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .toolbar-wide,
  ion-toolbar,
  ion-navbar.toolbar {
    &.ion-color-black{
      --background: var(--ion-color-black);
    }
    margin: 0 auto;
    position: relative;
    transform: inherit;
    width: 600px;
    &.toolbar-thick {
      min-height: 5rem;
      .back-button.show-back-button {
        top: 10px;
      }
    }
    &.toolbar-thin {
      height: 2rem;
      color: #D3EEE9 !important;
      background: #D3EEE9 !important;
    }
  }
  .toolbar-content {
    text-align: center;
  }
  .bar-button-md {
    text-transform: none;
  }
  .toolbar-wide {
    .back-button.show-back-button {
      background: transparent;
      left: 12px;
      top: 4px;
    }
    .bar-buttons {
      top: 10px;
      .bar-button {
        margin-top: -16px;
      }
    }
  }
  .back-button.show-back-button {
    margin-left: 0;
    padding-left: 0;
    position: absolute;
    top: 8px;
    z-index: 10;
    .back-button-icon-md {
      padding-left: 0;
    }
    &:hover {
      background-color: transparent;
      --background-hover: transparent;
    }
  }
  .bar-buttons {
    margin-right: 0;
    position: absolute;
    right: 0;
    top: 2px;
    .bar-button {
      &.bar-button-text {
        margin-top: 0;
      }
    }
  }
  .grid-basic-page {
    .label {
      font-weight: 300;
    }
    .plan-cleaning-times {
      .more-time {
        background-color: white !important;
        font-weight: 300;
      }
    }
    .cleaning-type-options {
      &.chosen-plan {
        margin-bottom: .25rem;
        ion-col {
          padding-top: 0;
          &:nth-child(2n) {
            align-self: flex-start;
          }
        }
      }
    }
  }
  .image-card {
    width: 40%;
  }
  .logo-white-header {
    margin-left: 0;
    left: 4px;
    position: absolute;
    top: 4px;
  }
  .item[detail-push] .item-inner {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns='http://www.w3.org/2000/svg'%…><path%20d='M2,20l-2-2l8-8L0,2l2-2l10,10L2,20z'%20fill='%23c8c7cc'/></svg>");
    padding-right: 32px;
    background-repeat: no-repeat;
    background-position: right 14px center;
    background-size: 14px 14px;
  }
}

.plan-once {
  text-align: left;
  margin: 0;
  padding-left: 2.5rem;
}

.chosen-plan.align-plan {
  align-items: flex-start;
}

.title-finish-feedback {
  margin-bottom: 3rem;
}

.adjust-title-cleaning {
  margin-bottom: 20px;
}

.radio-number .radio-icon {
  visibility: hidden;
}

.default-title {
  font: 1.6rem var(--title-booking);
  line-height: 2rem;
}

.icon-setting {
  width: 29px !important;
}

.row-item-page {
  font: 1.6rem var(--title-booking);
  .icon-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button {
    font-weight: 300;
    padding: 0;
  }
  .item-inner {
    padding: 20px 0;
  }
  .label-md {
    margin: 0;
  }
  .col {
    margin: 0px;
    padding-top: 0;
    padding-bottom: 0;
  }
  img {
    width: 38px;
  }
}

.split-pane-md.split-pane-visible > .split-pane-side[side=start] {
  border-left: 0;
}

.toolbar-ios ion-title{
  padding: 0px;
}



.menu-ModalAddPlan {
  background-color: #2BAFC8;
}

.menu-ScheduleAssurancePage {
  background-color: #FECD4E;
}

.menu-ModalAddPlan,
.menu-ScheduleAssurancePage {
  border-radius: 100px;
  padding: 10px;
}

.tidy-alert-success {
  background-color: var(--ion-color-alert_success);
  color: var(--ion-color-text);
  font-size: 1.6rem;
  margin: 10px 0 20px 0;
  padding: 10px;
  text-align: center;
}

.remove-wrap {
  overflow: initial;
  white-space: initial;
}

.focus-outline :focus {
  outline: 0;
}

.hidden {
  display: none;
}

.link-edit {
  cursor: pointer;
  color: #2CAFC8;
  text-decoration: underline;
}

.house-plan-image {
  margin: 0 auto 10px;
  width: 100px;
}

.page-title{
  text-transform: uppercase;
}

.white-space .toolbar-title{
  white-space: normal;
}

ion-spinner.white-spine{
  * {
    stroke: white;
  }
  &.spinner-circles circle,
  &.spinner-bubbles circle,
  &.spinner-dots circle {
    fill: white;
  }
}

#tidy-loading-modal{
  display: none;
  position: absolute;
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 100%;
  .loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .custom-loading-box{
    padding: 20px;
    width: 200px;
    border-radius: 8px;
    background: #fff;
    opacity: 1;
    transform: scale(1);
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .custom-loading-text{
    margin-left:15px;
  }
}

.absolute-camera {
  font-size: 25px;
  position: absolute;
  color: #2CAFC8;
}


.indicator-header {
  text-decoration: underline;
  font-weight: bold;
  margin: 0 0 20px 0;
}

ion-item {
  --ion-safe-area-right: 0;
  --full-highlight-height: 0;
  --background: transparent;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
}

form ion-input {
  margin: 10px 0 0;
}

.discount-call-out {
  color: var(--ion-color-tidy_green);
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.align-left {
  text-align: left;
}

.star-review {
  height: 1.4rem;
}

.social-header {
  font-family: georgia;
  font-size: 2rem;
  text-align: left;
  font-weight: bold;
}

.google-logo {
  height: 3rem;
  margin-right: 10px;
}

.title-ios, .title-md {
  padding: 0;
}

.alert-radio-group, .alert-checkbox-group{
  &::-webkit-scrollbar {
    width: 0.4em;
    display: block !important;
  }
}

.alert-radio-group, .alert-checkbox-group {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(--ion-color-caption);
    opacity: 0.1;
  }
}

.alert-radio-group, .alert-checkbox-group{
  &::-webkit-scrollbar-thumb {
    background-color: var(--ion-color-caption);
    outline: 1px solid var(--ion-color-caption_dark);
  }
}

tidy-radio-button {
  input[type="radio"]{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: ($break-large - 1px)) {
  ion-split-pane {
    background-color: var(--ion-color-header_black);
  }
  .background-app-image {
    display: none;
  }
}

@media screen and (min-width: $break-large) {
  .background-app-image {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }

  // TODO Old pages, remove as soon we deprecate all old pages
  page-my-house,
  page-sign-up,
  page-address,
  page-plan,
  hybrid-plan,
  page-confirm,
  page-modal-more-times,
  page-modal-more-plan,
  page-password-recovery
  {
    ion-header {
      background-color: var(--ion-color-black);
      .bar-button {
        font-size: 2rem;
        font-weight: 300;
      }
    }

    .tidy-content {
      width: 600px;
      background-color: #fff;
      border: 2px solid var(--ion-color-tidy_black);
      border-radius: 0;
      padding: 20px;
      position: relative;
    }

    .tidy-content.tidy-box:before {
      border: 1px solid var(--ion-color-tidy_black);
      bottom: 4px;
      content: "";
      left: 4px;
      position: absolute;
      right: 4px;
      top: 4px;
    }
  }
}

@media screen and (max-width: $break-large) {
  .wrapper-containing-block {
    position: relative;
    height: 100%;
  }
}

@media screen and (min-width: $break-large) {
  .desktop {
    background: #F6F6F6; //App background
    min-height: 100%;
    //border-top-left-radius: 6px; Adds rounded corner but doesn't work
  }
}

.default-snackbar .mat-simple-snackbar-action {
  color: var(--ion-color-tidy_green);
}

.full-page {
  min-height: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
}

.subcription-modal {
  .mat-dialog-container {
    background-color: #41cab7 !important;
  }

  ion-content {
    --background: #41cab7 !important;
  }
}

.next-steps-modal {
  width: 600px;
  height: 300px;
}

.right-side-modal {
  opacity: 1;
  margin-top: 60px !important;
  width: 500px;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  background-color: whitesmoke;
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
  border-top-left-radius: 8px;
  .desktop {
    background-color: whitesmoke;
    padding-bottom: 4rem;
    padding-top: 10px;
    padding-top: 1rem;
  }
  .ion-content-right-column {
    .desktop {
      overflow: hidden !important;
    }
    .right-side-wrapper-scroll {
      overflow: auto !important;
      height: 100% !important;
      padding-bottom: 10rem !important;
    }
    .custom-right-column {
      height: auto !important;
      min-height: auto !important;
      max-height: auto !important;
      overflow: hidden !important;
    }
    .black-column {
      background: #212121 !important;
      margin-bottom: 1rem;
    }
  }
}

.right-side-scroll-enabled {
  overflow: auto !important;
  height: auto !important;
  padding-bottom: 10rem !important;
}

.scrollable-content {
  height: calc(100% - 100px);
  overflow-y: auto;
  display: block;
}

.right-side-extra-bottom-padding {
  padding-bottom: 30rem !important;
}

.right-side-panel-header {
  border-top-left-radius: 8px;
  background-color: #1D2125;
  color: white;
  padding: 15px;
  border-top: 1px solid #738496;
  z-index: 10;
  position: relative;
  .drop-down-menu {
    margin-left: auto;
    margin-right: 10px;
  }
  .right-side-panel-title {
    padding: 0px;
    display: flex;
    align-items: center;
    .back-button {
      cursor: pointer;
      width: 25px;
      min-width: 25px;
      display: flex;
      margin-right: 10px;
    }
    .title-text {
      span {
        span {
          font-size: 24px;
        }
      }
    }
  }
  .cross-icon {
    cursor: pointer;
    width: 25px;
    min-width: 25px;
  }
}

.slide-in-right-animation {
  animation-name: slideInRight;
  animation-duration: 0.3s;
}

.slide-out-right-animation {
  animation-name: slideOutRight;
  animation-duration: 0.2s;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.mapboxgl-map {
  border-radius: 6px;
}

.message-skeleton-icon {
  width: 25px;
  height: 24px;
  border-radius: 100%;
  margin-right: 6px;
}

.message-skeleton-subject {
  width: 100px;
  height: 13px;
  border-radius: 6px;
}

.message-skeleton-body {
  width: 80%;
  height: 70px;
  border-radius: 6px;
}


.toast-error {
  color: white;
  --background: #c30808;
}

.toast-success {
  color: white;
  --background: #41cab7;
}

.toast-warning {
  color: white;
  --background: #f5a623;
}

.toast-info {
  color: white;
  --background: #416cca;
}

.desktop-title-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.desktop-item:not(:last-child) {
  padding-right: 15px;
}

.desktop-item {
  width: 100%;
}

.desktop-title-row-no-buttons {
  display: flex;
  align-items: center;
}

.desktop-title-row-pro-settings {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 0px;
}

.concierge-desktop-title-row {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 8px;
  margin-top: 12px;
}

.desktop-title-row-padding-fix {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 8px;
}

.sidebar-search-row {
  display: flex;
  align-items: center;
  gap: 15px;
  > :first-child {
    flex: 65%;
  }
  > :nth-child(2) {
    flex: 35%;
  }
  > :first-child:only-child {
    flex: 100%;
  }
}

.sidebar-nav-search {
  .search-input {
    .mat-form-field-appearance-outline .mat-form-field-flex {
      font-size: 10px !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      background: white !important;
      border-radius: 4px !important;
    }
    .mat-input-element {
      font-size: 16px !important;
    }
  }
}

.desktop-title-row-only-text {
  align-items: center;
  margin-bottom: 17px;
}

.desktop-title-text {
  color: black;
  display: inline;
  font: 700 30px Neuton, serif;
  line-height: 10px;
}

.desktop-nav-search {
  width: 200px;
  max-width: 55%;

  &.full-width {
    width: 100%;
    max-width: 100%;
  }

  .search-input {
    .mat-form-field-appearance-outline .mat-form-field-flex {
      font-size: 10px !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      background: white !important;
      border-radius: 4px !important;
    }
    .mat-input-element {
      font-size: 16px !important;
    }
  }
}

.white-search-input {
  .mat-form-field-appearance-outline .mat-form-field-flex {
    font-size: 10px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    background: white !important;
    border-radius: 4px !important;
  }
  .mat-input-element {
    font-size: 16px !important;
  }
}

.mobile-nav-search {
  width: 100%;
  margin-bottom: 10px;
  .search-input {
    .mat-form-field-appearance-outline .mat-form-field-flex {
      font-size: 10px !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      background: white !important;
    }
    .mat-input-element {
      font-size: 16px !important;
    }
  }
}

.search-input {
  .mat-form-field-appearance-outline .mat-form-field-flex {
    font-size: 10px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    background: white !important;
    border-radius: 4px !important;
  }
  .mat-input-element {
    font-size: 16px !important;
  }
}

.search-input-header {
  .mat-form-field-appearance-outline .mat-form-field-flex {
    font-size: 11px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    background: white !important;
    border-radius: 4px !important;
  }
  .mat-input-element {
    font-size: 16px !important;
  }
}

.schedule-view-mode-input {
  .mat-form-field-appearance-outline .mat-form-field-flex {
    font-size: 10px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    background: white !important;
    border-radius: 4px !important;
  }
  .mat-input-element {
    font-size: 16px !important;
  }
}

.desktop-nav-button {
  tidy-button {
    .wrapper {
      button {
        padding: 0px !important;
      }
    }
    .mat-button-wrapper {
      background: #333;
      display: flex !important;
      align-items: center !important;
      padding: 6px 16px 6px 12px !important;
      border-radius: 4px !important;
    }
  }
}

.desktop-nav-button-green {
  .mat-button-wrapper {
    background: #41cab7 !important;
  }
}

.desktop-hamburger-button {
  tidy-button {
    margin-bottom: 10px;
    .wrapper {
      button {
        padding: 0px !important;
      }
    }
    .mat-button-wrapper {
      width: 134px !important;
      background: black;
      display: flex !important;
      align-items: center !important;
      padding: 6px 16px 6px 12px !important;
      border-radius: 4px !important;
    }
  }
}

.desktop-hamburger {
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 1000;
}

.desktop-hamburger-icon {
  cursor: pointer;
  margin-top: 20px;
  margin-right: 10px;
  width: 30px;
  &.force-width {
    min-width: 30px;
  }
}

.desktop-hamburger-fab-list-container {
  right: 9px;
}

.desktop-title-icon {
  width: 22px;
  margin-left: auto;
  cursor: pointer;
}

.spinner-settings-container {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.align-right {
  margin-left: auto;
}

.desktop-back-icon {
  width: 22px;
  cursor: pointer;
}

.timestamp-caption {
  padding: 10px;
  background: rgba(0,0,0,.87);
  tidy-text {
    span {
      span {
        color: white;
      }
    }
  }
}

.remove-image-button {
  top: 0;
  right: 0;
  position: absolute;
  .mat-mini-fab.mat-accent {
    background-color: #191919 !important;
    box-shadow: none !important;
  }
}

@media screen and (min-width: $break-large) {
  .photo-note-job {
    width: 400px;
    .full-width {
      width: 400px;
    }
  }

  .timestamp-caption {
    width: 100%;
  }

  .before-after-video {
    width: 400px !important;
  }
}

@media screen and (max-width: $break-large) {
  .past-job-padding {
    padding: 70px 18px 5px !important;
  }
}
router-outlet#main, ion-modal {
  --background: whitesmoke;
}

.confirm-modal {
  --border-radius: 8px;
  --width: 500px;
  --height: 300px;
}

.tall-confirm-modal {
  --border-radius: 8px;
  --width: 500px;
  --height: 500px;
}

.mat-menu-content {
  padding: 0px !important;
}

.mat-menu-panel {
  min-height: 0 !important;
}

.white-select {
  .mat-form-field-appearance-outline .mat-form-field-flex {
    font-size: 10px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    background: white !important;
    border-radius: 4px !important;
  }
}

cdk-virtual-scroll-viewport {
  height: calc(100vh - 210px);
  width: 100%;
  overflow-y: auto;
}

cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
  padding: 5px;
}

.desktop-header {
  //This class replicates the styles of tidy-desktop-header
  //It is used in pages where FE built a component for the header, which doesn't work with tidy-desktop-header + tidy-wrapper
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  height: 40px;
}

.desktop-header-button {
  .wrapper {
    button {
      padding: 0px !important;
    }
  }
  .mat-button-wrapper {
    background: #333;
    display: flex !important;
    align-items: center !important;
    padding: 6px 16px 6px 12px !important;
    border-radius: 4px !important;
  }
}

.desktop-header-field {
  .mat-form-field-appearance-outline .mat-form-field-flex {
    font-size: 10px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    background: white !important;
    border-radius: 4px !important;
  }
  .mat-input-element {
    font-size: 16px !important;
  }
}

.desktop-header-field-top-nav {
  .mat-form-field-appearance-outline .mat-form-field-flex {
    font-size: 8px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    background: #22272B;
    border-radius: 6px !important;
  }
  .mat-form-field-appearance-outline:hover .mat-form-field-wrapper,
  .mat-form-field-appearance-outline.mat-focused .mat-form-field-wrapper {
    background: #292E32 !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: #738496 !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #738496 !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-end,
  .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-width: 1px !important;
  }
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: transparent !important;
  }
  .mat-form-field-appearance-outline .mat-focused {
    color: white !important;
  }
  .mat-form-field.mat-focused .mat-form-field-label {
    color: white !important;
  }
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    color: white !important;
    transform: translateY(calc(-1.2em - 4px)) scale(0.75) !important;
  }
  .mat-input-element {
    font-size: 14px !important;
    color: white !important;
    caret-color: white !important;
    font-weight: 400 !important;
  }
  .mat-form-field-appearance-outline.mat-focused:not(.invalid-field) .mat-form-field-label-wrapper {
    filter: blur(0) !important;
  }
  .mat-form-field-infix {
    padding: 10px !important;
    margin-left: 5px !important;
    border-top: 0.5em solid transparent;
  }
  .mat-form-field-infix .mat-form-field-label-wrapper label mat-label tidy-text {
    span {
      span {
        color: #738496 !important; // Changed to #738496
        font-weight: 400 !important;
      }
    }
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
  }

  // Add this new rule for the icon
  .mat-form-field-prefix {
    color: #738496 !important;
  }

  // Change the label color
  .mat-form-field-label {
    color: #738496 !important;
  }
}

.primary-button {
  .mat-button-wrapper {
    background: #41CAB7 !important;
  }
}

@media screen and (max-width: 1150px) {
  .form-filters {
    display: contents;
  }
}

.form-filters {
  display: flex;
}

.daterange-card {
  .row-component {
    div:hover {
      cursor: pointer;
    }
  }
}

.select-custom-input {
  .mat-form-field-appearance-outline .mat-form-field-flex {
    font-size: 11px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    background: white !important;
    border-radius: 4px !important;
  }
}

.select-custom-input-bigger {
  .mat-form-field-appearance-outline .mat-form-field-flex {
    font-size: 14px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    background: white !important;
    border-radius: 4px !important;
  }
}

.daterange-custom-container {
  .daterange-custom-input {
    .mat-form-field-appearance-outline .mat-form-field-flex {
      font-size: 11px !important;
      padding-bottom: 5px;
    }
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      background: white !important;
      border-radius: 4px !important;
    }
    .mat-select-arrow-wrapper {
      position: relative;
      top: 4px;
    }
    .mat-form-field-infix {
      padding-bottom: 6px;
    }
    .mat-select {
      font-size: 16px;
      font-weight: 300;
    }
    .mat-date-range-input-container {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.two-column-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .column > * {
    margin-bottom: 10px;
  }
}

.column {
  flex: 1;
  min-width: 300px;
}

@media (max-width: 768px) {
  .column {
    flex-basis: 100%;
  }
}
.billing-history-desktop-filter {
  max-width: 100%;
}

.menu-item-tooltip {
  font-weight: 325 !important;
  font-size: 14px !important;
  font-family: 'Inter', Arial, sans-serif !important;
  color: white !important;
  margin-left: 20px !important;
  position: relative !important;
  border: none !important;
  background: #1E2125;
  &::after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid #1E2125;
  }

  &.right {
    overflow: initial;
    margin-left: 1rem;
    &:after {
      left: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(270deg);
    }
  }

  &.below {
    overflow: initial;
    margin-top: 1rem;
    &:after {
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0);
    }
  }
}

.pros-desktop-header {
  //This class replicates the styles of tidy-desktop-header
  //It is used in pages where FE built a component for the header, which doesn't work with tidy-desktop-header + tidy-wrapper
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  height: 51px;
  margin-bottom: 10px;
}

.card-loading-spinner {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.header-loading-spinner {
  height: 25px;
  width: 25px;
  margin-left: auto;
  cursor: pointer;
}

.mapboxgl-canvas {
  z-index: 0;
}

.integrations-chip {
  border-radius: 5px;
  display: inline-block;
  margin-right: 5px;
  padding: 10px;
  cursor: pointer;
}

.integrations-chip-text {
  display: inline;
  font-family: "Inter", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: black;
}

.white {
  color: white;
}

tidy-wrapper.mobile {
  padding-top: var(--custom-ios-padding-top) !important;
}

@media (max-width: 768px) {
  tidy-wrapper.desktop {
    padding-top: var(--custom-ios-padding-top) !important;
  }
}

@media (min-width: 768px) {
  .content-column {
    tidy-wrapper.desktop {
      padding-top: 35px !important;
    }
  }

  ion-content {
    tidy-wrapper.desktop {
      border-radius: 8px 0px 0px 0px;
    }
  }
}

ion-modal {
  margin-top: 0px !important;
}

.charge-badge-success {
  margin: 0;
  margin-bottom: 1rem;
  background-color: #ddfdf9 !important;
  color: #41cab7 !important;
  font-size: 12px !important;
  ion-icon {
    margin-right: 5px !important;
    color: #41cab7 !important;
  }
  ion-label {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #41cab7 !important;
  }
}

.charge-badge-pending {
  margin: 0;
  margin-bottom: 1rem;
  background-color: #fdf3dd !important;
  color: #ca7d41 !important;
  font-size: 12px !important;
  ion-icon {
    margin-right: 5px !important;
    color: #ca7d41 !important;
  }
  ion-label {
    font-weight: 500 !important;
    color: #ca7d41 !important;
    font-size: 12px !important;
  }
}

.charge-badge-canceled {
  margin: 0;
  margin-bottom: 1rem;
  background-color: rgba(195, 8, 8, 0.15) !important;
  color: #c30808 !important;
  font-size: 12px !important;
  ion-icon {
    margin-right: 5px !important;
    color: #c30808 !important;
  }
  ion-label {
    font-weight: 500 !important;
    color: #c30808 !important;
    font-size: 12px !important;
  }
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.two-column-form-row{
  display: flex;
  >*{
    display: flex;
    flex-basis: 100%;
    flex: 1;
    margin: 0;
  }
  tidy-button{
    display: block;
  }
  >*:first-child {
    margin: 0 10px 0 0;
    max-width: calc(50% - 5px);
  }
  >*:last-child {
    margin: 0 0px 0 0;
    max-width: calc(50% - 5px);
  }
}

.three-column-form-row{
  display: flex;
  >*{
    display: flex;
    flex-basis: 100%;
    flex: 1;
    margin: 0;
  }
  tidy-button{
    display: block;
  }
  >*:first-child {
    margin: 0 10px 0 0;
    max-width: calc(33% - 5px);
  }
  >*:nth-child(2) {
    margin: 0 10px 0 0;
    max-width: calc(33% - 5px);
  }
  >*:last-child {
    max-width: calc(33% - 5px);
  }

  tidy-error-message {
    left: 0;
    width: 100%;
  }
}




//TIDY UI:


@import 'intl-tel-input/build/css/intlTelInput.css';

ion-menu.menu-pane-visible {
  backdrop-filter: blur(10px);
}

.transform-uppercase {
  text-transform: uppercase;
}

@font-face {
  font-family: 'saint-andrews-queen';
  src:
    url('/assets/fonts/saint-andrews-queen.eot') format('eot'),
    url('/assets/fonts/saint-andrews-queen.woff2') format('woff2'),
    url('/assets/fonts/saint-andrews-queen.woff') format('woff'),
    url('/assets/fonts/Saint-Andrews-Queen.ttf') format('truetype'),
    url('/assets/fonts/saint-andrews-queen.svg#saint-andrews_queen') format('svg');
  font-weight: normal;
  font-style: normal;
}

.tidy-filter-overlay-panel {
  max-width: min-content;
}

.wiggle-animation {
  animation: wiggle 1.5s infinite;
  animation-direction: alternate-reverse;
}

@keyframes wiggle {
  10%, 90% {
    transform: translate(-1px, 0);
  }

  20%, 80% {
    transform: translate(2px, 0);
  }

  30%, 50%, 70% {
    transform: translate(-4px, 0);
  }

  40%, 60% {
    transform: translate(4px, 0);
  }
}

.custom-loading {
  --backdrop-opacity: 0;
  .loading-wrapper {
    background: transparent;
    box-shadow: 0 0;
  }
}

.tidy-ripple-desktop-and-mobile-color {
  @media screen and (max-width: 884px) {
    .mat-ripple-element {
      background-color: rgba(251, 251, 251, 0.2);
    }
  }
}

.tidy-ripple-wrapper {
  border-radius: 50%;
}

.header-button {
  display: flex;
  align-items: center;
  background-color: #333333;
  border-radius: 4px;
  border: 0.5px solid #D5D5D5;
  &:hover {
    background-color: #2a2a2a;
    transition: background-color 0.5s ease;
  }
  border-radius: 4px;
  padding: 6px 9px;
  cursor: pointer;
  height: 33px;
}

.header-button.primary {
  background-color: #41cab7;
  &:hover {
    background-color: #42c0ac;
    transition: background-color 0.5s ease;
  }
}

.custom-initials-icon {
  .mat-icon-button {
    width: 18px !important;
    height: 18px !important;
  }
  .initials {
    font-size: 9px !important;
    margin-right: 0px !important;
  }
}

.onboarding-back-button {
  margin-left: -5px;
  cursor: pointer;
}

.onboarding-modal-header {
  background: #F6F6F6;
  margin: -20px calc(-20px - var(--scrollbar-gutter, 8px)) 20px -20px;
  padding: 20px;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid #E0E0E0;
}

.onboarding-modal {
  --border-radius: 8px;
  --width: 600px;
  --height: auto;
}

.advanced-setup-modal {
  --border-radius: 8px;
  --width: 500px;
  --height: 150px;
}

.video-tutorial-modal {
  --border-radius: 8px;
  --width: 500px;
  --height: 350px;
}

.card-checkbox {
  cursor: pointer;
  margin-bottom: 10px;
  &:hover {
    background: #F6F6F6;
  }
}

.selected-card-checkbox {
  background: #d8d8d8;
  &:hover {
    background: #d8d8d8;
  }
}

.link-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}