.round-button {
  border-radius: 100px;
  --border-radius: 100px;
  width: 57px;
  height: 57px;
  padding: 0px !important;
  margin: 0;
  margin-bottom: 10px;
  --padding-start: 0;
  --padding-end: 0;
}

.round-button-image {
  width: 40%;
  height: auto;
  margin: 2px 0 0 1px;
}

.round-button-text {
  font-weight: lighter;
}