.box-sub-form{
  position: relative;
  .back-button{
    --box-shadow: none;
    --background: none;
    color: #333;
    margin-top: -10px;
    margin-left: 16px;
  }
  .close-icon {
    right: 28px;
    font-size: 25px;
    margin-right: 0;
  }
  ion-select{
    .select-text{
      padding-left: 19px;
    }
    .select-icon-inner {
      color: #000 !important;
    }
  }
}

.select-icon-inner {
  opacity: 1;
}

@media screen and (max-width: 1000px) {
  .box-sub-form .back-button{
    display: none;
  }
}

page-todos .tidy-content .tidy-form, page-todo-room .tidy-content .tidy-form{
  border: 0 !important;
  --min-height: auto;
  box-shadow: none !important;
  padding: 5px 0 5px 33px;
  margin-top: 5px;
  background-size: 12px;
  background-position: 0 10px;
  background-repeat: no-repeat;
  background-color: #fff;
  font-size: 14px;
  --highlight-color-focused: none;
  --highlight-color-valid: none;
  --highlight-color-invalid: none;

  &.item-has-focus {
    border: 0 !important;
    box-shadow: none !important;
    input, textarea{
      color: var(--ion-color-selected_form);;
      box-shadow: none;
      border-bottom: 1px solid var(--ion-color-selected_form);
    }
  }

  &.italic-text{
    input, textarea{
      font-style: italic;
    }
  }

  .icon-border{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  &.default-select{
    position: relative;
    background-color: transparent;
    margin: 0 auto;
    width: 100px;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 15px;
  }

  &.item-has-focus input::placeholder, &.item-has-focus textarea::placeholder{
    color: var(--ion-color-selected_form);;
    font-weight: bold;
  }

  input, textarea, ion-textarea, ion-input, ion-select row{
    --placeholder-opacity: 1;
    background: transparent;
    margin: 0;
    padding: 0;
    border: 0;
  }



  input, textarea, .item-select{
    font-size: 14px;
    font-family: var(--title-booking);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    --min-height: auto;
    width: 100%;
  }

  &.large-text{
    padding: 0;
    margin: 0;
    textarea{
      padding: 0;
      margin: 0 auto;
      min-height: 43px;
      width: 70%;
      text-align: center;
      margin-bottom: 10px;
      padding-bottom: 5px;
    }
    &.textarea-has-focus textarea, &.textarea-has-focus textarea::placeholder, textarea, &.tidy-input{
      font-size: 30px;
      background: transparent;
      --min-height: auto;
      font-weight: bold;
      font-family: georgia;
    }
  }

  &.tidy-checkbox {
    padding: 0;
    margin-left: 1px;
    .checkbox-icon{
      position: relative;
      width: 18px;
      height: 18px;
      border-color: #ccc;
      padding: 0;
      margin: 0;
    }

    .checkbox-icon, .checkbox-inner{
      background: transparent;
      border-radius: 0;
      width: 18px;
      height: 18px;
    }

    .checkbox-checked{
      background: var(--ion-color-tidy_green);
      .checkbox-inner{
        background: #fff;
        width: 3px;
        height: 2px;
        top: 6px;
        left: 1px;
        box-shadow:
          2px 0 0 white,
          4px 0 0 white,
          4px -2px 0 white,
          4px -4px 0 white,
          4px -6px 0 white,
          4px -8px 0 white;
        transition: unset;
        transform: rotate(45deg);
      }
    }
  }

  input::placeholder, textarea::placeholder{
    font-style: italic;
  }

  .donot-text{
    color: #000000 !important;
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .donot-text-empty {
    font-style: italic;
  }

  input::placeholder, textarea::placeholder, .label{
    color: #B3B3B3;
    font-family: var(--title-booking);
    font-size: 14px;
    font-weight: 300;
  }

  ion-textarea{
    font-weight: normal;
    line-height: normal;
    font-size: 16px;
    color: #000000;
  }

  &.tidy-input {
    background-image: url("/assets/img/icons/pencil-icon.svg");
    background-size: 20px;
    background-position: 0 0;
  }

  &.input-home-access{
    background-image: url("/assets/img/icons/lock-icon-outline.svg");
    background-size: 20px;
    background-position: 0 0px;
  }


  &.input-home-access, &.input-home-closing{
    background-image: url("/assets/img/icons/door-icon-outline.svg");
    background-size: 20px;
    background-position: 0 0px;
    textarea{
      font-size: 16px;
      line-height: 19px;
    }
  }

  .custom-textarea{
    textarea{
      height: 100%;
      overflow: hidden;
    }
  }

  &.do-not, &.do-not.big-text{
    background-image: url("/assets/img/icons/icon-hand.png");
    background-position: 0 0;
    &.item-has-focus {
      input, ion-label, textarea{
        color: var(--ion-color-selected_form) !important;
      }
    }
    input, ion-label, textarea{
      color: #000;
      font-size: 16px;
      font-weight: 300;
    }
    textarea{
      font-family: var(--title-booking);
      text-indent: 58px;
    }
    ion-label{
      font-weight: bold;
      font-family: georgia;
      position: absolute;
      top: 0px;
      left: 0;
      z-index: 1;
    }
  }

  &.big-text{
    --min-height: auto;
    background-position: 2px 5px;
    background-size: 20px;
    &.black-star {
      background-image: url("/assets/img/icons/star_blank_black.svg");
    }
    &.blue-star {
      background-image: url("/assets/img/icons/star_blank.svg");
    }
    &.item-has-focus input, &.item-has-focus input::placeholder, input, &.tidy-input, textarea{
      font-size: 16px;
      font-weight: bold;
      font-family: georgia;
    }

    &.important-task {
      background-image: url("/assets/img/icons/star_yellow.svg");
      background-color: var(--ion-color-alert);

      + photo-note {
        margin-top: 10px;
      }
    }
  }

  &.ionic-icon{
    flex-wrap: nowrap;
    display: flex;
    ion-select{
      margin-top: 2px;
    }
    ion-item{
      width: 80% !important;
      padding-left: 10px;
    }
    ion-icon{
      color: #B3B3B3;
      margin-top: 5px;
      padding-left: 0px;
      font-size: 20px;
    }
  }

  &.tidy-input.no-icon, &.tidy-select.no-icon{
    background: none;
    padding-left: 0;
  }


  &.tidy-select{
    background-size: 16px;
    background-position: 0 2px;
    margin-top: 0;
    padding-bottom: 0px;
    ion-icon{
      margin: 0;
    }
    .tidy-input, .tidy-form{
      margin: 0;
      padding: 0;
      --min-height: auto;
    }
    .custom-select{
      background-position: 0 3px;
      background-size: 20px;
      background-repeat: no-repeat;
      padding-left: 33px;
    }
    &.no-line{
      .grid,  .item-select{
        opacity: 1;
        border: 0;
        --min-height: auto;
      }
      .icon-border{
        border: 0;
      }
    }
    .floor-icon{
      background-image: url("/assets/img/icons/floor-icon.svg");
    }
    ion-select{
      max-width: 100%;
      padding: 0 5px 0px 0;
      margin-top: 2px;
      font-weight: 400;
      .select-text{
        font-weight: normal;
        font-family: var(--title-booking);
        color: #000000;
        font-size: 14px;
      }
      .select-icon-inner {
        color: #000;
      }
    }
    .col, .grid {
      margin: 0;
      padding: 0;
    }
    .row{
      margin: 0;
      padding: 0;
    }
    &.no-border{
      .grid, .row{
        border: 0;
      }
    }
  }
}


