.tidy-square {
  position: relative;
  background-color: white;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.24), 0px 0px 3px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  margin-bottom: 26px;
  padding: 19px 19px 19px 14px;
  &.adjust-close-button {
    padding-right: 58px;
  }
  &.square-selected {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.24);
  }
  .close-icon {
    right: 29px;
    top: 20px;
    margin-top: 2px;
    font-size: 25px;
    color: #2CAFC8;
    &.todo {
      right: 25px;
      margin: 0;
      font-size: 26px;
    }
  }
  .item,
  .item .item-inner {
    background: transparent;
    min-height: auto;
    border: 0;
    padding: 0;
  }
  p {
    font-family: var(--title-booking);
    font-size: 12px;
  }
  .item-dont {
    color: #FF6C78;
    strong {
      margin-right: 5px;
      font-weight: bold;
    }
  }
  ion-label {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;
    font-family: var(--title-booking);
    font-size: var(--regular-text-size);
    color: var(--ion-color-regular_text);
    font-weight: 300;
    min-height: 3rem;
    ion-label {
      height: auto;
    }
  }
  ion-list-header.item {
    margin-top: 0 !important;
    margin-bottom: 0;
    padding-top: 0;
  }
  &::before {
    content: ' ';
    position: absolute;
    background-image: url('/assets/img/icons/pencil-icon.svg');
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px;
    width: 20px;
    height: 30px;
    right: 18px;
    top: 22px;
  }
  &.home-box::before,
  &.todo-disable::before,
  &.form-box::before {
    background: transparent;
  }
  &.loading::before {
    background-color: #ffffff;
    background-image: url("/assets/img/icons/loading.gif");
    background-size: 25px 25px;
    background-position: right bottom;
    background-repeat: no-repeat;
  }
  &.saving::before {
    background-color: #ffffff;
    background-image: url("/assets/img/icons/check.png");
    background-size: 25px 25px;
    background-position: right bottom;
    background-repeat: no-repeat;
  }
}
