$colors: (
  primary: #387ef5,
  secondary: #525659,
  danger: #f53d3d,
  light: #f4f4f4,
  light_green: #EFF9F7,
  text: #434343,
  text_success: #d6e9c6,
  dark: #222,
  black: #000,
  yellow: #FFF6BA,
  favorite: #69BB7B,
  ivory: #FCFCFA,
  caption: #A7B0B5,
  tidy_background: #FCF7F0,
  tidy_green: #41CAb7,
  tidy_black: #434343,
  tidy_black_alpha: rgba(67,67,67, 0.6),
  caption_dark: #8D8D8D,
  disabled: #E7E7E6,
  link: #2CAFC8,
  link_secondary: #4EACC5,
  mighty: #51ADC6,
  default_border: #BEBFC0,
  light_border: #DEDEDE,
  alert: #fef9b7,
  alert_success: #dff0d8,
  notes: rgba(red, 0.5),
  radio_checkbox_color: #525659,
  radio_group_grid_label: black,
  box_input: rgba(255,255,255,0.92),
  input_color: #7f7f7f,
  btn_login: #F6F5EA,
  swiper_pagination: #FFF,
  swiper_pagination_active: #000,
  auto_complete_border: #dedede,
  selected_item: #e3fff9,
  transparent: transparent,
  tidy_yellow: #FECD4E,
  light_color: #666
);

:root {
  font-size: 62.5%;
  text-size-adjust: 100%;
  --secondary-menu-width: 460px;
  --skeleton-menu-width: 60px;
  --skeleton-menu-margin-left: 0px;
  --align-item-flex-start: flex-start;
  --ion-color-primary: #387ef5;
  --ion-color-secondary: #525659;
  --ion-color-danger: #f53d3d;
  --ion-color-light: #f4f4f4;
  --ion-color-light_green: #EFF9F7;
  --ion-color-text: #434343;
  --ion-color-text_success: #d6e9c6;
  --ion-color-dark: #222;
  --ion-color-black: #000;
  --ion-color-yellow: #FFF6BA;
  --ion-color-favorite: #69BB7B;
  --ion-color-ivory: #FCFCFA;
  --ion-color-caption: #A7B0B5;
  --ion-color-tidy_background: #FCF7F0;
  --ion-color-tidy_green: #41cab7;
  --ion-color-tidy_red: #EB5757;
  --ion-color-tidy_black: #434343;
  --ion-color-tidy_black_alpha: rgba(67,67,67, 0.6);
  --ion-color-caption_dark: #8D8D8D;
  --ion-color-disabled: #E7E7E6;
  --ion-color-link: #2CAFC8;
  --ion-color-link_secondary: #4EACC5;
  --ion-color-mighty: #51ADC6;
  --ion-color-default_border: #BEBFC0;
  --ion-color-light_border: #DEDEDE;
  --ion-color-alert: #fef9b7;
  --ion-color-alert_success: #dff0d8;
  --ion-color-notes: rgba(red, 0.5);
  --ion-color-radio_checkbox_color: #525659;
  --ion-color-radio_group_grid_label: black;
  --ion-color-box_input: rgba(255, 255, 255, 0.92);
  --ion-color-input_color: #7f7f7f;
  --ion-color-btn_login: #F6F5EA;
  --ion-color-swiper_pagination: #FFF;
  --ion-color-swiper_pagination_active: #000;
  --ion-color-auto_complete_border: #dedede;
  --ion-color-selected_item: #e3fff9;
  --ion-color-selected_negative: #FFE8E8;
  --ion-color-transparent: transparent;
  --ion-color-tidy_yellow: #FECD4E;
  --ion-color-tidy_blue: #2BAFC8;
  --ion-color-todos_tidy_green: #41CAB7;
  --ion-color-selected_form: #00AABA;
  --ion-color-bg_transparent: rgba(251, 251, 251, 0.85);
  --ion-color-title_description: #989898;
  --ion-color-regular_text: #333;
  --ion-color-header_black: #212121;

  // --ion-color-black: #000000;
  // --ion-color-link: #2CAFC8;
  // --ion-color-tidy_green: #41CAb7;
  // --ion-color-tidy_black: #434343;

  /** primary **/
  // --ion-color-primary: #387ef5;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-ekhade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  // /** secondary **/
  --ion-color-secondary: #525659;
  // --ion-color-secondary-rgb: 12, 209, 232;
  // --ion-color-secondary-contrast: #ffffff;
  // --ion-color-secondary-contrast-rgb: 255, 255, 255;
  // --ion-color-secondary-shade: #0bb8cc;
  // --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f53d3d;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --break-mobile-landscape: 480px;
  --break-mobile: 320px;
  --break-large: 721px;
  --title: 'Inter', Georgia, Times, "Times New Roman", serif;
  --title-booking: 'Inter', Helvetica, Arial, sans-serif;
  --title-description: 'Inter', Helvetica, Arial, sans-serif;
  --regular-text-size: 1.6rem;
  --sub-title-size: 2.5rem;
  --big-title-size: 3.0rem;

  // each item in color map
  @each $name, $color in $colors {
    --color-#{"" + $name}: $color;
  }

  // @function var(--ion-color-$color-name) {
  //   @return var(--color-#{$color-name});
  // }
  --transparent-high: rgba(196,196,196,.1);
}

// @import "ionic.theme.default";
// @import "ionic.ionicons";
// @import "roboto";
// @import "noto-sans";


@media (any-hover: hover){
  :host(.button-solid.ion-color:hover) .button-native {
    --background-hover: #000;
    --ion-color-base: var(--ion-color-tidy_green);
    background: var(--background-hover);
    color: #000;
  }
}

:host(.button-full) .button-native {
  height: 500px !important;
}

$break-large: 885px;
$title-font: 700 24px Neuton, serif;
$header-font: 700 20px Neuton, serif;
