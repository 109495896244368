.tidy-cards {
  background-color: white;
  border: 2px solid var(--ion-color-tidy_black);
  margin: 0;
  padding: 4px;
  position: relative;
  width: 100%;
  margin: 2px 0 0 1px;
  .tidy-card-text {
    padding: 1rem 1rem 0.5rem 1rem;
  }
  .header {
    font-weight: bold;
    padding-bottom: 5px;
  }
  ion-label {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  .content {
    p {
      font-size: 1.6rem;
      margin-bottom: 10px;
    }
  }
  &:before {
    border: 1px solid var(--ion-color-tidy_black);
    bottom: 2px;
    content: "";
    left: 2px;
    position: absolute;
    right: 2px;
    top: 2px;
  }
}
