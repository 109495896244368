tidy-card-input, photo-note {

  display: block;
  background: none;
  padding-left: 0;
  border: 0 !important;
  box-shadow: none !important;
  padding-bottom: 12px;

  .text-input, .text-area{
    margin: 0;
  }

  .tidy-container {
    justify-content: start;
    border-bottom: none;
  }

  .tidy-item {
    align-self: center;
    margin: 0;
  }

  &.item-has-focus {
    border: 0 !important;
    box-shadow: none !important;
    input,
    textarea {
      color: #000;
      box-shadow: none;
      border: 0;
      border-bottom: 1px solid var(--ion-color-selected_form);
    }
  }


  .icon-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  ion-icon {
    color: #000;
  }

  .icon-align {
    padding-bottom: 7px;
  }

  .icon-align > :first-child{
    min-width: 23px;
    display: flex;
    justify-content: center;
    margin-right: 14px;
}

  &.default-select {
    position: relative;
    margin: 0 auto;
    width: 100px;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 15px;
  }

  &.item-has-focus input::placeholder,
  &.item-has-focus textarea::placeholder {
    color: var(--ion-color-selected_form);
    font-weight: bold;
  }

  .tidy-input {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  input,
  textarea,
  ion-textarea,
  ion-input {
    background: transparent;
    margin: 0;
    padding: 0;
    padding-bottom: 6px;
    font-size: 15px;
    font-family: 'Inter';
    font-weight: 300;
    border: 0;
    border-radius: 0;
    flex: 1;
  }

  ion-textarea, ion-input {
    font-family: 'Inter';
    font-weight: 300;
    line-height: normal;
    font-size: 15px;
    color: #000;
  }

  .custom-textarea {
    padding: 0;
    display: flex;
    flex: 1;
    & >:first-child {
      box-sizing: content-box;
      padding-bottom: 8px;
      display: flex;
      height: 100%;
    }
    & >:first-child textarea{
      min-height: 17px;
      height: 100%;
      overflow: hidden;
    }
  }

  [camera-icon] {
    align-self: center;
  }
}
