page-todos,
page-love-it,
delete-room,
what-happened,
homekeeper,
page-todo-room,
page-add-todo,
app-billing-history,
page-homekeeper-options,
cleaning-history,
my-schedule,
homekeeper-contact,
no-plan-page,
confirm-refund,
cleanings-history-page,
cancel-account,
add-gift-code,
confirm-add-gift-code,
address-info,
page-modal-same-day-cleaning,
manage-addresses,
to-dos-settings,
confirm-account-update,
user-account,
prices,
lost-item,
confirm-lost-item,
page-feedback-success,
confirm-request-times,
request-times,
cancel-request-time,
accept-countered,
reject-countered,
request-countered {
  ion-list, .list-md{
    background: transparent;
  }

  @import 'tidy-square.scss';
  .custom-toast{
    font-family: var(--title-booking);
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #000000;
    span{
      margin-bottom: 15px;
      margin-left: 15px;
      display: block;
    }
  }
  p{
    font-size: 16px;
  }

  nav-bar-desktop ion-title{
    white-space: normal;
    margin: 0 10px;
  }

  .big-size-text{
    font-size: 18px;
  }
  .page-title{
    text-transform: inherit;
  }

  .align-icon-img{
    display: flex;
  }

  .img-category {
    width: 30px;
    height: 20px;
    margin-right: 10px;
  }

  .no-wrap, .no-wrap ion-label{
    white-space: normal;
  }

  .close-icon{
    position: absolute;
    right: 0;
    font-size: 25px;
    z-index: 3;
    top: 7px;
  }

  .suggested-items{
     padding: 0 5px;
     border: 1px solid #ccc;
     margin: 10px 0;
  }

  ion-card-header{
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  ion-card-header, nav-bar-desktop .toolbar-title{
    font-weight: bold;
    font-size: 2.3rem;
    font-family: var(--title);
  }

  nav-bar-desktop{
    .bar-button-md, .custom-back-button{
      margin: 0 0 0 8px;
      padding: 0;
      min-height: 20px;
    }
  }

  .big-title{
    font-family: var(--title);
    font-size: var(--big-title-size);
    color: var(--ion-color-black);
    font-weight: bold;
    padding-bottom: 0.5rem;
    text-transform: none;
    text-align: center;
  }

  ion-card-content.title-description{
    font-family: var(--title-description);
    font-size: var(--regular-text-size);
    color: var(--ion-color-title_description);
    padding: 0;
    font-weight: 300;

    &.-black-text {
      color: var(--ion-color-black);
    }
  }

  .box-header{
    margin-bottom: 1.9rem;
  }

  .sub-title{
    font-family: var(--title);
    font-size: var(--sub-title-size);
    color: var(--ion-color-regular_text);
    text-transform: none;
    font-weight: bold;
    width: 90%;
    margin-top: 0;
  }

  .tidy-content{
    max-width: 407px;
    background: var(--ion-color-bg_transparent);
    padding: 20px;
  }


  .auto-overflow {
    line-height: 0;
    background-color: var(--ion-color-alert);
    margin-bottom: 20px;
  }

  .tidy-todos-actions {
    .fab .ion-icon{
      width: 62px;
      height: 62px;
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12);
    }
  }

  ion-item.item.tidy-item-list{
    padding-bottom: 11px;
    &:last-child{
      padding-bottom: 0;
    }
  }

  .item-access{
    line-height: 20px;
  }

  @media screen and (max-width: 400px){
    .tidy-content{
      margin: 10px;
    }
  }

  @media screen and (max-width: 1000px){
    .tidy-content{
      padding: 0;
      margin: 0 auto;
      margin-top: 55px;
      max-width: 367px;
      background: none !important;
    }
    .scroll-content{
      padding: 0 !important;
    }
    .adjust-fab {
      height: 100%;
      position: absolute;
      right: -28px;
      top: 0;
      width: 56px;
    }
  }

  .custom-card-input {
    position: relative;
    .tidy-input{
      align-self: flex-start;
      width: 90%;
    }
    ion-input, ion-textarea, ion-select{
      padding: 0 !important;
      margin: 0 20px 0 0px !important;
      min-width: 50px !important;
      input, textarea{
        align-self: flex-start;
        padding: 0  0 5px 0 !important;
      }
    }
  }
}
