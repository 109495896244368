tidy-label {
  font-family: Georgia;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 16px;
  &.space-title{
    margin-bottom: 20px;
    display: block;
  }
}
